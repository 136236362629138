import React, { useEffect, useState } from 'react'
import { ICompanyManager, IContactStatus, ICounty, IMunicipality, ISelectOption } from '@local/src/App.types'
import { Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, Stack, TextField } from '@mui/material'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { useHistory } from 'react-router-dom'
import { orgBasepath } from '@local/src/basename'
import { objectToQueryParams, parse } from '@local/src/Utils/helpers/queryString'
import Loading from '@local/src/Components/Loading/Loading'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { useGetCountiesQuery, useGetMunicipalitiesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { useGetCompanyContactStatusesQuery, useGetCompanyManagersQuery, useGetLevelOneRolesQuery, useGetLevelTwoRolesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { useGetUnionsQuery } from '@local/src/Utils/network/endpoints/unionsApi'

import { ISearchContactRequest, searchContacts } from '../../Contacts.actions'

export interface IContactFormValues {
  fullName: string
  mobileNumber: string
  email: string
  organizationalNumber: string
  roleLevel1: ISelectOption[]
  roleLevel2: ISelectOption[]
  status: ISelectOption
  customerManagersIds: ISelectOption[]
  unionIds: ISelectOption[]
  countyCodes: ISelectOption[]
  municipalityCodes: ISelectOption[]
  isMainContact: boolean
}

export const initialValues: IContactFormValues = {
  fullName: '',
  mobileNumber: '',
  email: '',
  organizationalNumber: '',
  roleLevel1: [],
  roleLevel2: [],
  status: { label: 'Aktiv i företaget', value: '0' },
  customerManagersIds: [],
  unionIds: [],
  countyCodes: [],
  municipalityCodes: [],
  isMainContact: false,
}

const ContactAdvancedSearchForm = () => {

  const history = useHistory()

  const [formValues, setFormValues] = useState<IContactFormValues>(initialValues)

  const { data: municipalitiesFromQuery, isLoading: isLoadingMunicipalities } = useGetMunicipalitiesQuery()
  const { data: countiesFromQuery, isLoading: isLoadingCounties } = useGetCountiesQuery()
  const { data: roleLevelOne, isLoading: isLoadingRoleLevelOne } = useGetLevelOneRolesQuery()
  const { data: roleLevelTwo, isLoading: isLoadingRoleLevelTwo } = useGetLevelTwoRolesQuery()
  const { data: companyManagersFromQuery, isLoading: isLoadingCompanyManagers } = useGetCompanyManagersQuery()
  const { data: unions, isLoading: isLoadingUnions } = useGetUnionsQuery()
  const { data: contactStatuses, isLoading: isLoadingContactStatuses } = useGetCompanyContactStatusesQuery()

  const municipalities = municipalitiesFromQuery?.map(m => ({ value: m.id, label: m.name, county: m.countyId }) as IMunicipality) ?? []
  const counties = countiesFromQuery?.map(c => ({ value: c.id, label: c.name }) as ICounty) ?? []
  const companyManagers = companyManagersFromQuery?.map(cm => ({ value: cm.id, label: cm.fullName, email: cm.email }) as ICompanyManager) ?? []

  const loaded =
    !isLoadingMunicipalities &&
    !isLoadingCounties &&
    !isLoadingRoleLevelOne &&
    !isLoadingRoleLevelTwo &&
    !isLoadingCompanyManagers &&
    !isLoadingUnions &&
    !isLoadingContactStatuses

  const dispatch = useAppDispatch()
  const countiesValues = formValues.countyCodes?.map(x => x.value)



  const municipalitiesOptions: ISelectOption[] = formValues.countyCodes?.length
    ? municipalities?.filter(municipality => countiesValues.includes(municipality.county))
    : municipalities

  const submitForm = (values: IContactFormValues) => {
    dispatch(searchContacts(values))
    updateUrl(values)
  }

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitForm(formValues)
  }

  const resetForm = () => {
    setFormValues(initialValues)
  }

  const updateUrl = (values: IContactFormValues) => {
    const queryModel = { ...values, advanced: 'true' }
    const queryString = objectToQueryParams(queryModel)
    history.push(`${orgBasepath}/kontakt${queryString}`)
  }

  useEffect(() => {
    if (!loaded)
      return

    const query = parse(location.search) as unknown as ISearchContactRequest
    const values: IContactFormValues = {
      fullName: query.fullName || '',
      mobileNumber: query.mobileNumber || '',
      email: query.email || '',
      organizationalNumber: query.organizationalNumber || '',
      roleLevel1: roleLevelOne?.filter(x => query.roleLevel1?.includes(x.value.toString())) || initialValues.roleLevel1,
      roleLevel2: roleLevelTwo?.filter(x => query.roleLevel2?.includes(x.value.toString())) || initialValues.roleLevel2,
      status: contactStatuses?.find(x => x.value === query.status) ?? initialValues.status,
      customerManagersIds: companyManagers?.filter(x => query.customerManagersIds?.includes(x.value.toString())) || initialValues.customerManagersIds,
      unionIds: unions?.filter(x => query.unionIds?.includes(x.value.toString())) || initialValues.unionIds,
      countyCodes: counties?.filter(x => query.countyCodes?.includes(x.value.toString())) || initialValues.countyCodes,
      municipalityCodes: municipalities?.filter(x => query.municipalityCodes?.includes(x.value)) || initialValues.municipalityCodes,
      isMainContact: query.isMainContact?.toString() === 'true',
    }

    setFormValues(values)

    const queryParameters = new URLSearchParams(location.search)
    const hasFilterParametersInQueryString = Array.from(queryParameters.keys()).some(parameter =>
      Object.keys(formValues).includes(parameter)
    )

    if (hasFilterParametersInQueryString) {
      submitForm(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  if (!loaded) {
    return (<Loading />)
  }

  return (
    <Stack marginTop={2}>
      <form onSubmit={handleSubmitForm}>
        <Box display='grid' gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }} gap={3}>

          <TextField
            value={formValues.fullName}
            onChange={(event) => setFormValues({ ...formValues, fullName: event.target.value })}
            name='fullName'
            label='Namn'
          />

          <TextField
            value={formValues.mobileNumber}
            onChange={(event) => setFormValues({ ...formValues, mobileNumber: event.target.value })}
            name='mobileNumber'
            label='Mobilnummer'
          />

          <TextField
            value={formValues.email}
            onChange={(event) => setFormValues({ ...formValues, email: event.target.value })}
            name='email'
            label='E-post'
          />

          <Autocomplete
            multiple
            data-testid='category'
            options={roleLevelOne}
            value={formValues.roleLevel1}
            onChange={(_, value) => setFormValues({ ...formValues, roleLevel1: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Kategori'
                name='roleLevel1'
              />
            )}
          />

          <Autocomplete
            multiple
            data-testid='role'
            options={roleLevelTwo.filter((item, index, self) => index === self.findIndex(obj => obj.value === item.value))}
            value={formValues.roleLevel2}
            onChange={(_, value) => setFormValues({ ...formValues, roleLevel2: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField {...params} label='Roll' />
            )}
          />

          <Dropdown
            label='Status'
            value={formValues.status?.value}
            options={contactStatuses.filter(x => x.value)}
            getOptionLabel={(val: IContactStatus) => val.label}
            getOptionValue={(val: IContactStatus) => val.value}
            onChange={(event) =>
              setFormValues({
                ...formValues,
                status: contactStatuses?.find(item => item.value === event.target.value)
              })
            }
          />

          <Autocomplete
            multiple
            data-testid='customerManager'
            options={companyManagers}
            value={formValues.customerManagersIds}
            onChange={(_, value) => setFormValues({ ...formValues, customerManagersIds: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField {...params} label='Ansvarig' />
            )}
          />

          <TextField
            value={formValues.organizationalNumber}
            onChange={(event) => setFormValues({ ...formValues, organizationalNumber: event.target.value })}
            name='organizationalNumber'
            label='Organisationsnummer'
          />

          <Autocomplete
            multiple
            options={unions}
            value={formValues.unionIds}
            onChange={(_, value) => setFormValues({ ...formValues, unionIds: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField {...params} label='Fackförbund' />
            )}
          />

          <Autocomplete
            multiple
            data-testid='county'
            options={counties}
            value={formValues.countyCodes}
            onChange={(_, value) => setFormValues({ ...formValues, countyCodes: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField {...params} label='Län' />
            )}
          />

          <Autocomplete
            multiple
            data-testid='municipality'
            options={municipalitiesOptions}
            value={formValues.municipalityCodes}
            onChange={(_, value) => setFormValues({ ...formValues, municipalityCodes: value })}
            getOptionLabel={(option: ISelectOption) => option.label}
            renderInput={(params) => (
              <TextField {...params} label='Kommun' />
            )}
          />
        </Box>

        <Box
          display='flex'
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems='center'
          justifyContent='space-between'
          marginTop={1}
        >
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.isMainContact}
                  onChange={(_, checked) => setFormValues({ ...formValues, isMainContact: checked })}
                  name='isMainContact'
                />
              }
              label='Visa endast huvudkontakter'
            />

            <InformationPopover
              infoText='Syftet med att märka upp en kontakt som en huvudkontakt
                        är att du snabbt kan få fram de personer som du jobbar
                        med mest frekvent, exempelvis vid gruppmejl.'
            />
          </Box>

          <Box display='flex' gap={2}>
            <Button type='submit' aria-label='Sök med avancerade sökparametrar'>Sök</Button>
            <Button onClick={resetForm} variant='outlined'>Rensa</Button>
          </Box>

        </Box>
      </form>
      <Divider sx={{ marginTop: 3 }} />
    </Stack>
  )
}

export default ContactAdvancedSearchForm
