import { useHistory, useLocation } from 'react-router-dom'

const ARRAY_SEPARATOR = ','
export const useQueryParams = () => {
    const history = useHistory()
    const { search } = useLocation()

    const params = new URLSearchParams(search as string)

    const getStringParamValue = (name: string, fallback: string = null): string => {
        const value = params.get(name)?.trim()
        if (!value)
            return fallback

        return decodeURIComponent(value)
    }
    const getStringArrayParamValue = (name: string, fallback: string[] = []): string[] => {
        const value = params.get(name)?.trim()
        if (!value)
            return fallback

        return decodeURIComponent(value).split(ARRAY_SEPARATOR).map(e => e)
    }

    const getNumberParamValue = (name: string, fallback: number = null): number => {
        const value = params.get(name)?.trim()
        return parseNumber(value, fallback)
    }
    const getNumberArrayParamValue = (name: string, fallback: number[] = []): number[] => {
        const value = params.get(name)?.trim()
        if (!value)
            return fallback

        return value.split(ARRAY_SEPARATOR).map(e => parseNumber(e, null)).filter(e => e !== null)
    }
    const parseNumber = (value: string, fallback: number) => {
        if (!value || isNaN(+value))
            return fallback

        return Number(value)
    }

    const getBooleanParamValue = (name: string, fallback: boolean = null): boolean => {
        const value = params.get(name)?.trim().toLowerCase()
        return parseBoolean(value, fallback)
    }
    const getBooleanArrayParamValue = (name: string, fallback: boolean[] = []): boolean[] => {
        const value = params.get(name)?.trim()
        if (!value)
            return fallback

        return value.split(ARRAY_SEPARATOR).map(e => parseBoolean(e, null)).filter(e => e !== null)
    }
    const parseBoolean = (value: string, fallback: boolean) => {
        switch (value) {
            case 'true': return true
            case 'false': return false
            default: return fallback
        }
    }


    const setParamValue = (name: string, value: string | number | boolean) => {
        const paramValue = value?.toString()
        if (paramValue) {
            params.set(name, encodeURIComponent(paramValue))
            history.replace({ pathname: location.pathname, search: params.toString() })
        }
        else {
            params.delete(name)
        }
    }
    const setArrayParamValue = (name: string, value: (string | number | boolean)[]) => {
        if (value.length === 0) {
            params.delete(name)
            history.replace({ pathname: location.pathname, search: params.toString() })
            return
        }

        const paramValue = value.map(e => e.toString()).join(ARRAY_SEPARATOR)
        if (paramValue) {
            params.set(name, encodeURIComponent(paramValue))
            history.replace({ pathname: location.pathname, search: params.toString() })
            return
        }
    }


    const clearParams = () => {
        for (const key of [...Array.from(params.keys())]) {
            params.delete(key)
        }
        history.push({ pathname: location.pathname, search: params.toString() })
    }

    const getRawParams = () => params

    return {
        getStringParamValue,
        getStringArrayParamValue,
        getNumberParamValue,
        getNumberArrayParamValue,
        getBooleanParamValue,
        getBooleanArrayParamValue,
        setParamValue,
        setArrayParamValue,
        clearParams,
        getRawParams
    }
}