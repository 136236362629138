import { IContactStatus, IRoleLevelTwo, ISelectOption } from '@local/src/App.types'
import { IGroupWorkplace } from '@local/src/Scenes/Company/CompanyTabs/CompanyGroup/CompanyGroup'
import { ICompany } from '@local/src/Scenes/Company/Company.model'
import { IWorkplaceType } from '@local/src/Models/Domain/WorkplaceType.model'
import { IWorkplaceManager } from '@local/src/Models/Domain/WorkplaceManager.model'
import { IWorkplaceEligibility } from '@local/src/Models/Domain/WorkplaceEligibility.model'
import { IWorkplaceStatus } from '@local/src/Models/Domain/WorkplaceStatus.model'
import { ITrrIndustry } from '@local/src/Models/Domain/TrrIndustry.model'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'

import { organisationsregistretApi } from '../organisationsregistretApi'

import { IWorkplaceSearchResponseApiModel, mapToWorkplace } from './WorkplacesSearch/IWorkplacesSearchResponseApiModel'

export const workplacesApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyGroup: builder.query<IGroupWorkplace[], string>({
      query: (companyGroupParentOrganizationalNumber) => {
        const urlSearchParams = new URLSearchParams()
        urlSearchParams.append('koncernmoderOrganisationsnummer', companyGroupParentOrganizationalNumber)
        urlSearchParams.append('isHuvudkontor', String(true))
        return `arbetsstallen/huvudkontor-i-koncern?${urlSearchParams.toString()}`
      },
    }),

    getWorkplaceTypes: builder.query<IWorkplaceType[], void>({
      query: () => ({ url: 'workplaces/companyTypes', method: 'GET' }),
      transformResponse: (data: { companyTypeId: string; name: string }[]) =>
        data.map((d) => ({ id: String(d.companyTypeId), name: d.name }) as IWorkplaceType),
    }),

    getCompanyManagers: builder.query<IWorkplaceManager[], void>({
      query: () => ({ url: 'employees', method: 'GET' }),
      transformResponse: (
        data: { employeeId: string; firstName: string; lastName: string; fullName: string; emailAddress: string }[]
      ) =>
        data.map(
          (d) =>
            ({
              id: d.employeeId,
              firstName: d.firstName,
              lastName: d.lastName,
              fullName: d.fullName,
              email: d.emailAddress,
            }) as IWorkplaceManager
        ),
    }),

    getCompanyStatuses: builder.query<IWorkplaceStatus[], void>({
      query: () => ({ url: 'workplaces/companyStatuses', method: 'GET' }),
      transformResponse: (data: { companyStatusCode: string; companyStatusDescription: string }[]) =>
        data.map((d) => ({ id: d.companyStatusCode, description: d.companyStatusDescription }) as IWorkplaceStatus),
    }),

    getCompanyContactStatuses: builder.query<IContactStatus[], void>({
      query: () => ({ url: 'workplaces/contacts/contactStatuses', method: 'GET' }),
      transformResponse: (data: { statusId: string; statusText: string }[]) =>
        data.map(
          (d) =>
            ({
              value: d.statusId,
              label: d.statusText,
            }) as IContactStatus
        ),
    }),

    getLevelOneRoles: builder.query<ISelectOption[], void>({
      query: () => ({ url: 'workplaces/contacts/roleLevelOnes', method: 'GET' }),
      transformResponse: (data: { roleLevel1Id: string; roleLevel1Text: string }[]) =>
        data.map((d) => ({ value: d.roleLevel1Id, label: d.roleLevel1Text }) as ISelectOption),
    }),

    getLevelTwoRoles: builder.query<IRoleLevelTwo[], void>({
      query: () => ({ url: 'workplaces/contacts/roleLevelTwos', method: 'GET' }),
      transformResponse: (data: { roleLevel2Id: string; roleLevel2Text: string; roleLevel1Id: string }[]) =>
        data.map((d) => ({ value: d.roleLevel2Id, label: d.roleLevel2Text, level1: d.roleLevel1Id }) as IRoleLevelTwo),
    }),

    getEligibilityStatuses: builder.query<IWorkplaceEligibility[], void>({
      query: () => ({ url: 'workplaces/eligibilityStatuses', method: 'GET' }),
      transformResponse: (data: { eligibilityStatusCode: string; eligibilityStatusDescription: string }[]) =>
        data.map(
          (d) => ({ id: d.eligibilityStatusCode, description: d.eligibilityStatusDescription }) as IWorkplaceEligibility
        ),
    }),

    getMyWorkplaces: builder.query<IWorkplace[], void>({
      query: () => ({ url: 'workplaces/mine', method: 'GET' }),
      transformResponse: (data: IWorkplaceSearchResponseApiModel[]) => data.map(mapToWorkplace),
    }),

    getTrrIndustries: builder.query<ITrrIndustry[], void>({
      query: () => ({ url: 'workplaces/branscher', method: 'GET' }),
      transformResponse: (data: { code: string; description: string }[]) =>
        data.map((d) => ({ id: d.code, description: d.description }) as ITrrIndustry),
    }),

    getCompanyDetails: builder.query<ICompany, string>({
      query: (companyId: string) => ({ url: `workplaces/${companyId}`, method: 'GET' }),
    }),
  }),
})

export const {
  useLazyGetCompanyGroupQuery,
  useGetWorkplaceTypesQuery,
  useGetCompanyManagersQuery,
  useGetCompanyStatusesQuery,
  useGetCompanyContactStatusesQuery,
  useGetLevelOneRolesQuery,
  useGetLevelTwoRolesQuery,
  useGetEligibilityStatusesQuery,
  useGetMyWorkplacesQuery,
  useLazyGetCompanyDetailsQuery,
  useGetTrrIndustriesQuery,
} = workplacesApi
