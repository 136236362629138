import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useGetWorkplaceTypesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { loadedFilterFromUrl, setSelectedWorkplaceTypes } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const WorkplaceTypeFilter = ({ isSearching }: IFilterProps) => {
    const { data: workplaceTypes, isFetching: isLoadingWorkplaceType, isError: hasFailedToLoadWorkplaceTypes } = useGetWorkplaceTypesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedWorkplaceTypes
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (workplaceTypes?.length > 0) {
            const idsFromParams = getStringArrayParamValue('workplaceTypes')
            dispatch(setSelectedWorkplaceTypes(workplaceTypes.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workplaceTypes])

    return (
        <Autocomplete
            options={workplaceTypes ?? []}
            value={selectedWorkplaceTypes}
            onChange={(_, value) => dispatch(setSelectedWorkplaceTypes(value))}
            getOptionLabel={(wt) => wt.name}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='HK/AP'
                error={hasFailedToLoadWorkplaceTypes}
                helperText={hasFailedToLoadWorkplaceTypes ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingWorkplaceType || hasFailedToLoadWorkplaceTypes || isSearching}
        />
    )
}