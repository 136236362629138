import React, { useEffect } from 'react'
import { Box, Button, Checkbox, Collapse, Divider, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import { Clear, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { IRange } from '@local/src/Models/Misc/Range.model'

import { RangeFilter } from './Filters/RangeFilter'
import { setNameOrgNumber, setWhiteCollarRange, setPayrollRange, setIsLocallyImportant, reset, loadedFilterFromUrl } from './WorkplaceSearch.slice'
import { CountyFilter } from './Filters/CountyFilter'
import { MunicipalityFilter } from './Filters/MunicipalityFilter'
import { WorkplaceTypeFilter } from './Filters/WorkplaceTypeFilter'
import { TrrIndustryFilter } from './Filters/TrrIndustryFilter'
import { NationalManagerFilter } from './Filters/NationalManagerFilter'
import { LocalManagerFilter } from './Filters/LocalManagerFilter'
import { LocalContactFilter } from './Filters/LocalContactFilter'
import { EligibilityFilter } from './Filters/EligibilityFilter'
import { WorkplaceStatusFilter } from './Filters/WorkplaceStatusFilter'



export const WHITE_COLLAR_RANGE_MIN = 0
export const WHITE_COLLAR_RANGE_MAX = 10000

export const PAYROLL_DISPLAY_FACTOR = 1000000
export const PAYROLL_RANGE_MIN = 0
export const PAYROLL_RANGE_MAX = 15000000000

export interface IWorkplaceSearchFilterProps {
    isSearching: boolean,
    onSearch: () => void
}

export const WorkplaceSearchFilter = ({ isSearching, onSearch }: IWorkplaceSearchFilterProps) => {
    const { getStringParamValue, getBooleanParamValue, setParamValue, getNumberParamValue } = useQueryParams()
    const showFilters = getBooleanParamValue('showFilters', false)
    const theme = useTheme()

    const showTrrIndustryFilter = useIsFeatureEnabled('OR-Frontend_showTrrIndustry_temp_240626')

    const {
        nameOrgNumber,
        whiteCollarRange,
        payrollRange,
        isLocallyImportant
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const nameOrgNumberFromParams = getStringParamValue('nameOrgNumber', '')
        const whiteCollarFrom = getNumberParamValue('whiteCollarFrom', WHITE_COLLAR_RANGE_MIN)
        const whiteCollarTo = getNumberParamValue('whiteCollarTo', WHITE_COLLAR_RANGE_MAX)
        const payrollFrom = getNumberParamValue('payrollFrom', PAYROLL_RANGE_MIN)
        const payrollTo = getNumberParamValue('payrollTo', PAYROLL_RANGE_MAX)
        const isLocallyImportant = getBooleanParamValue('isLocallyImportant', false)

        dispatch(setNameOrgNumber(nameOrgNumberFromParams))
        dispatch(setWhiteCollarRange({ from: whiteCollarFrom, to: whiteCollarTo }))
        dispatch(setPayrollRange({ from: payrollFrom, to: payrollTo }))
        dispatch(setIsLocallyImportant(isLocallyImportant))

        if (nameOrgNumberFromParams !== ''
            || whiteCollarFrom !== WHITE_COLLAR_RANGE_MIN
            || whiteCollarTo !== WHITE_COLLAR_RANGE_MAX
            || payrollFrom !== PAYROLL_RANGE_MIN
            || payrollTo !== PAYROLL_RANGE_MAX
            || isLocallyImportant !== false
        ) {
            dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetFilters = () => {
        dispatch(reset())
    }


    return (
        <Stack marginBottom={3}>
            <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} alignItems='center'>
                <TextField
                    label='Sök på valfri text eller ett organisationsnummer'
                    value={nameOrgNumber}
                    onChange={(e) => dispatch(setNameOrgNumber(e.currentTarget.value))}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            onSearch()
                    }}
                    sx={{ gridColumn: '1 / 3' }}
                    disabled={isSearching}
                    InputProps={{
                        endAdornment: <IconButton size="small" onClick={onSearch}><Search sx={{ color: `${theme.palette.primary.main} !important` }} /></IconButton>
                    }}
                />
                <Button
                    variant='text'
                    onClick={() => setParamValue('showFilters', !showFilters)}
                    endIcon={showFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    sx={{ gridColumn: '3' }}
                >
                    Förfina din sökning
                </Button>
            </Box>
            <Collapse in={showFilters}>
                {showTrrIndustryFilter && <FilterLayoutWithTrrIndustry
                    isSearching={isSearching}
                    onSearch={onSearch}
                    resetFilters={resetFilters}
                    whiteCollarRange={whiteCollarRange}
                    payrollRange={payrollRange}
                    isLocallyImportant={isLocallyImportant}
                />}
                {!showTrrIndustryFilter && <FilterLayoutWithoutTrrIndustry
                    isSearching={isSearching}
                    onSearch={onSearch}
                    resetFilters={resetFilters}
                    whiteCollarRange={whiteCollarRange}
                    payrollRange={payrollRange}
                    isLocallyImportant={isLocallyImportant}
                />}
                <Divider />
            </Collapse>
        </Stack >
    )
}

interface IFilterLayoutProps {
    isSearching: boolean,
    whiteCollarRange: IRange,
    payrollRange: IRange,
    isLocallyImportant: boolean,
    onSearch: () => void
    resetFilters: () => void
}
const FilterLayoutWithTrrIndustry = ({ isSearching, whiteCollarRange, payrollRange, isLocallyImportant, onSearch, resetFilters }: IFilterLayoutProps) => {
    const dispatch = useAppDispatch()

    return (
        <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} marginBottom={2} marginTop={2}>
            <CountyFilter isSearching={isSearching} />
            <MunicipalityFilter isSearching={isSearching} />
            <WorkplaceTypeFilter isSearching={isSearching} />
            <TrrIndustryFilter isSearching={isSearching} />
            <NationalManagerFilter isSearching={isSearching} />
            <LocalManagerFilter isSearching={isSearching} />
            <LocalContactFilter isSearching={isSearching} />
            <EligibilityFilter isSearching={isSearching} />

            <RangeFilter
                label='Antal tjänstemän enligt TRR'
                from={whiteCollarRange.from}
                to={whiteCollarRange.to}
                min={WHITE_COLLAR_RANGE_MIN}
                max={WHITE_COLLAR_RANGE_MAX}
                onChange={(from, to) => dispatch(setWhiteCollarRange({ from: from, to: to }))}
                disabled={isSearching}
            />
            <RangeFilter
                label='Lönesumma'
                from={payrollRange.from / PAYROLL_DISPLAY_FACTOR}
                to={payrollRange.to / PAYROLL_DISPLAY_FACTOR}
                min={PAYROLL_RANGE_MIN / PAYROLL_DISPLAY_FACTOR}
                max={PAYROLL_RANGE_MAX / PAYROLL_DISPLAY_FACTOR}
                onChange={(from, to) => dispatch(setPayrollRange({ from: from * PAYROLL_DISPLAY_FACTOR, to: to * PAYROLL_DISPLAY_FACTOR }))}
                unit='MSEK'
                disabled={isSearching}
            />

            <WorkplaceStatusFilter isSearching={isSearching} />
            <Stack direction='row' alignItems='center'>
                <Checkbox checked={isLocallyImportant} onChange={(_, checked) => dispatch(setIsLocallyImportant(checked))} disabled={isSearching} />
                <Typography variant='body1'>Lokalt strategisk</Typography>
            </Stack>

            <Stack direction='row' sx={{ gridColumn: '4', justifySelf: 'end' }} gap={1}>
                <Button variant='contained' startIcon={<Search />} onClick={() => onSearch()} disabled={isSearching}>Sök</Button>
                <Button variant='outlined' startIcon={<Clear />} onClick={resetFilters} disabled={isSearching}>Rensa</Button>
            </Stack>
        </Box>
    )
}

interface IFilterLayoutProps {
    isSearching: boolean,
    whiteCollarRange: IRange,
    payrollRange: IRange,
    isLocallyImportant: boolean,
    onSearch: () => void
    resetFilters: () => void
}
const FilterLayoutWithoutTrrIndustry = ({ isSearching, whiteCollarRange, payrollRange, isLocallyImportant, onSearch, resetFilters }: IFilterLayoutProps) => {
    const dispatch = useAppDispatch()

    return (
        <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} marginBottom={2} marginTop={2}>
            <CountyFilter isSearching={isSearching} />
            <MunicipalityFilter isSearching={isSearching} />
            <WorkplaceTypeFilter isSearching={isSearching} />
            <EligibilityFilter isSearching={isSearching} />
            <NationalManagerFilter isSearching={isSearching} />
            <LocalManagerFilter isSearching={isSearching} />
            <LocalContactFilter isSearching={isSearching} />

            <Stack direction='row' alignItems='center'>
                <Checkbox checked={isLocallyImportant} onChange={(_, checked) => dispatch(setIsLocallyImportant(checked))} disabled={isSearching} />
                <Typography variant='body1'>Lokalt strategisk</Typography>
            </Stack>

            <RangeFilter
                label='Antal tjänstemän enligt TRR'
                from={whiteCollarRange.from}
                to={whiteCollarRange.to}
                min={WHITE_COLLAR_RANGE_MIN}
                max={WHITE_COLLAR_RANGE_MAX}
                onChange={(from, to) => dispatch(setWhiteCollarRange({ from: from, to: to }))}
                disabled={isSearching}
            />
            <RangeFilter
                label='Lönesumma'
                from={payrollRange.from / PAYROLL_DISPLAY_FACTOR}
                to={payrollRange.to / PAYROLL_DISPLAY_FACTOR}
                min={PAYROLL_RANGE_MIN / PAYROLL_DISPLAY_FACTOR}
                max={PAYROLL_RANGE_MAX / PAYROLL_DISPLAY_FACTOR}
                onChange={(from, to) => dispatch(setPayrollRange({ from: from * PAYROLL_DISPLAY_FACTOR, to: to * PAYROLL_DISPLAY_FACTOR }))}
                unit='MSEK'
                disabled={isSearching}
            />

            <WorkplaceStatusFilter isSearching={isSearching} />
            <br />

            <Stack direction='row' sx={{ gridColumn: '4', justifySelf: 'end' }} gap={1}>
                <Button variant='contained' startIcon={<Search />} onClick={onSearch} disabled={isSearching}>Sök</Button>
                <Button variant='outlined' startIcon={<Clear />} onClick={resetFilters} disabled={isSearching}>Rensa</Button>
            </Stack>
        </Box>
    )
}