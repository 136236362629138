import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { formatOrgNr } from '@local/src/Utils/helpers'

import { IGroupWorkplace } from './CompanyGroup'
import { ActivityLink, OrganizationalNumberLink, WorkplaceLink } from './Fragments'

export const companyGroupColumns = (workplaceId: string): GridColDef<IGroupWorkplace>[] => {
  return [
    {
      headerName: 'Företagsnamn',
      field: 'name',
      renderCell: ({ row }) => formatWorkplaceName(row, workplaceId),
      flex: 1,
      minWidth: 400
    },
    {
      headerName: 'Org.nr',
      field: 'organisationsnummer',
      renderCell: ({ row }) => formatOrganizationalNumber(row),
      flex: 1,
      maxWidth: 120,
    },
    {
      headerName: 'Nationellt ansvarig',
      field: 'nationelltKundansvarig',
      renderCell: ({ row }) => tableTooltip(row.nationelltKundansvarig ?? ''),
      flex: 1,
    },
    {
      headerName: 'Lokalt ansvarig',
      field: 'lokaltKundansvarig',
      renderCell: ({ row }) => tableTooltip(row.lokaltKundansvarig ?? ''),
      flex: 1,
    },
    {
      headerName: 'Senaste händelse',
      field: 'senasteAktivitet',
      renderCell: ({ row }) => formatLastActivity(row),
      flex: 1,
      maxWidth: 225,
    },
    {
      headerName: 'Händelsedatum',
      field: 'senasteAktivitet.datum',
      renderCell: ({ row }) => tableTooltip(formatLastActivityDate(row)),
      flex: 1,
      maxWidth: 130,
    },
  ]
}

const formatWorkplaceName = (w: IGroupWorkplace, currentId: string): JSX.Element | string => {
  let namn = w.namn
  if (w.isModerbolag) {
    namn = 'Moderbolag - ' + namn
    if (!w.isAnslutet) namn += ' - Ej anslutet'
  }
  return !w.isAnslutet || w.id === currentId ? namn : <WorkplaceLink workplace={{ ...w, namn }} />
}

const formatLastActivity = ({ senasteAktivitet }: IGroupWorkplace): JSX.Element | string => {
  const canLink = senasteAktivitet?.id && senasteAktivitet?.aktivitetTypNamn
  return canLink ? <ActivityLink activity={senasteAktivitet} /> : senasteAktivitet?.aktivitetTypNamn ?? ''
}

const formatOrganizationalNumber = (w: IGroupWorkplace): JSX.Element | string => {
  const orgNrFormatted = formatOrgNr(w.organisationsnummer)
  return w.isModerbolag && !w.isArbetsstalle ? orgNrFormatted : <OrganizationalNumberLink orgNumber={orgNrFormatted} />
}

const formatLastActivityDate = (w: IGroupWorkplace): string => w.senasteAktivitet?.datum.substring(0, 10) ?? ''