import React from 'react'
import SearchTabs from '@local/Scenes/Home/Tabs/SearchTabs'
import { Route, Switch, useLocation } from 'react-router-dom'
import { companyUrl, contactUrl, orgUrl, projectUrl } from '@local/src/basename'
import { Box } from '@mui/material'
import ContactsForm from '@local/Scenes/Home/Tabs/Contacts/ContactsForm'
import ContactAdvancedSearchResult from '@local/Scenes/Home/Tabs/Contacts/ContactAdvancedSearch/ContactAdvancedSearchResult'
import ContactSearchResult from '@local/Scenes/Home/Tabs/Contacts/ContactSearchResult'
import Projects from '@local/Scenes/Home/Tabs/Projects/Projects'

import { WorkplaceSearch } from './Tabs/Companies/WorkplaceSearch/WorkplaceSearch'

const Home = (): JSX.Element => {
  const isAdvancedSearch = useLocation().search.includes('advanced')

  return (
    <>
      <SearchTabs />
      <Box marginTop={3}>
        <Switch>
          <Route path={[companyUrl, orgUrl]} exact>
            <WorkplaceSearch />
          </Route>
          <Route path={contactUrl} exact>
            <>
              <ContactsForm />
              <Box marginTop={3}>
                {isAdvancedSearch ? <ContactAdvancedSearchResult /> : <ContactSearchResult />}
              </Box>
            </>
          </Route>
          <Route path={projectUrl} exact>
            <Projects />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default Home
