import { axiosInstance } from '@local/Utils/network/axios.config'
import {
  deleteContactFailed,
  deleteContactStarted,
  deleteContactSuccess,
  searchContactsFailure,
  searchContactsInitiate,
  searchContactsSuccess,
  searchMyContactsFailure,
  searchMyContactsInitiate,
  searchMyContactsSuccess,
} from '@local/Scenes/Home/Tabs/Contacts/Contacts.reducers'
import { AppDispatch } from '@local/Store/configureStore'

import { IContactFormValues } from './ContactAdvancedSearch/ContactAdvancedSearchForm/ContactAdvancedSearchForm'

export interface ISearchContactRequest {
  fullName: string
  mobileNumber: string
  email: string
  organizationalNumber: string
  roleLevel1: string[]
  roleLevel2: string[]
  status: string
  customerManagersIds: string[]
  unionIds: string[]
  countyCodes: string[]
  municipalityCodes: string[]
  isMainContact: boolean
}

export const displayMyContacts = () => (dispatch: AppDispatch) => {
  dispatch(searchMyContacts(''))
}

export const searchMyContacts = (criteria: string) => (dispatch: AppDispatch) => {
  dispatch(searchMyContactsInitiate())

  axiosInstance.get(`myContacts?searchCriteria=${criteria}`)
    .then(res => {
      dispatch(searchMyContactsSuccess({ result: res.data }))
    })
    .catch(() => {
      dispatch(searchMyContactsFailure())
    })
}

export const searchContacts = (query: IContactFormValues) => (dispatch: AppDispatch) => {

  const request: ISearchContactRequest = {
    fullName: query.fullName,
    email: query.email,
    isMainContact: query.isMainContact,
    mobileNumber: query.mobileNumber,
    organizationalNumber: query.organizationalNumber,
    customerManagersIds: query.customerManagersIds?.map(x => x.value.toString()),
    roleLevel1: query.roleLevel1?.map(x => x.value.toString()),
    roleLevel2: query.roleLevel2?.map(x => x.value.toString()),
    status: query.status?.value.toString(),
    unionIds: query.unionIds?.map(x => x.value.toString()),
    countyCodes: query.countyCodes?.map(x => x.value.toString()),
    municipalityCodes: query.municipalityCodes?.map(x => x.value.toString()),
  }

  dispatch(searchContactsInitiate())

  axiosInstance.post('contacts', request)
    .then(res => {
      dispatch(searchContactsSuccess({ result: res.data }))
    })
    .catch(() => {
      dispatch(searchContactsFailure())
    })
}

export const deleteContact = (workplaceId: string, contactId: string) => (dispatch: AppDispatch) => {
  dispatch(deleteContactStarted())

  axiosInstance.delete(`workplaces/${workplaceId}/contacts/${contactId}`)
    .then(() => {
      dispatch(deleteContactSuccess())
      dispatch(searchMyContacts(''))
    })
    .catch(() => {
      dispatch(deleteContactFailed())
    })
}
