import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star'
import { companyContactUrl, contactDetailsEditUrl } from '@local/src/basename'
import { useAppDispatch } from '@local/Store/configureStore'
import { fetchDetailedContactData } from '@local/Scenes/Company/Company.actions'
import { resetContactsTab } from '@local/Scenes/Company/Company.reducers'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { Box, Button, Divider, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import Loading from '@local/src/Components/Loading/Loading'

import { detailedContactSelector } from '../../../Company.selectors'

const DetailedContact = () => {

  const history = useHistory()
  const dispatch = useAppDispatch()
  const contact = useStateSelector(detailedContactSelector)
  const { companyGuid, contactGuid } = useParams<{ companyGuid: string; contactGuid: string }>()

  const editContact = () => history.push(contactDetailsEditUrl(contact.workplaceId, contact.contactId))

  useEffect(() => {
    dispatch(fetchDetailedContactData(companyGuid, contactGuid))

    return () => {
      dispatch(resetContactsTab())
    }
  }, [companyGuid, contactGuid, dispatch])

  const handleGoBack = () => history.push(companyContactUrl(contact.workplaceId))

  if (!contact) return <Loading />

  const createdDate = contact?.createdBy.timestamp.substring(0, 10)
  const createdTime = contact?.createdBy.timestamp.substring(11, 16)

  const updatedDate = contact?.updatedBy?.timestamp?.substring(0, 10) ?? ''
  const updatedTime = contact?.updatedBy?.timestamp?.substring(11, 16) ?? ''

  return (
    <Stack maxWidth={752}>
      <LinkWithIcon
        text="Tillbaka till kontaktpersoner"
        onClick={handleGoBack}
        icon={<ChevronLeftIcon fontSize="small" />}
      />

      <Typography variant='h5' alignItems='center' display='flex' gap={1} marginTop={2} marginBottom={1}>
        {contact.isMainContact && (
          <Tooltip title='Huvudkontakt'>
            <StarIcon color='primary' data-testid='isMainContact' />
          </Tooltip>
        )}
        {contact.fullName}
      </Typography>

      <Box display='flex' flexDirection='column' gap={2}>
        <ListItem disableGutters>
          <ListItemText primary='Senast uppdaterad' secondary={
            `${updatedDate || createdDate}, ${createdTime || updatedTime} -
            ${contact?.updatedBy?.name ? contact.updatedBy.name : contact.createdBy?.name}`
          }/>
        </ListItem>

        <Divider />

        <ListItem disableGutters>
          <ListItemText primary='Mobil' secondary={contact.mobile.number} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText primary='Telefon' secondary={contact.phone.number} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText primary='E-post' secondary={contact.email} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText primary='Linkedin' secondary={contact.linkedIn} />
        </ListItem>
      </Box>

      <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
        <Typography variant='h6'>Roll och funktion</Typography>

        <ListItem disableGutters>
          <ListItemText primary='Kategori' secondary={contact.roleLevelOne.roleLevel1Text} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText primary='Roll' secondary={contact.roleLevelTwo.roleLevel2Text} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText primary='Status' secondary={contact.status.statusText} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary='Notering'
            secondary={contact.comment}
            secondaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItem>
      </Box>

      <Button variant='outlined' onClick={editContact} sx={{ alignSelf: 'baseline', marginTop: 3 }}>Redigera</Button>
    </Stack>
  )
}

export default DetailedContact
