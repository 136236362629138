import { combineReducers } from 'redux'
import createEmail from '@local/Blocks/CreateEmail/CreateEmail.reducer'
import { organisationsregistretApi } from '@local/Utils/network/organisationsregistretApi'

import company from '../Scenes/Company/Company.reducers'
import home from '../Scenes/Home/Home.reducers'
import activity from '../Scenes/Company/CompanyTabs/Activities/Activities.reducers'
import project from '../Scenes/Company/CompanyTabs/Projects/Projects.reducers'
import contacts from '../Scenes/Home/Tabs/Contacts/Contacts.reducers'
import workplaceSearch from '../Scenes/Home/Tabs/Companies/WorkplaceSearch/WorkplaceSearch.slice'

export default () =>
  combineReducers({
    activity,
    company,
    contacts,
    createEmail,
    home,
    project,
    workplaceSearch,
    [organisationsregistretApi.reducerPath]: organisationsregistretApi.reducer,
  })
