import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useGetEligibilityStatusesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { loadedFilterFromUrl, setSelectedEligibilities } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const EligibilityFilter = ({ isSearching }: IFilterProps) => {
    const { data: eligibilities, isFetching: isLoadingEligibilities, isError: hasFailedToLoadEligibilities } = useGetEligibilityStatusesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedEligibilities
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (eligibilities?.length > 0) {
            const idsFromParams = getStringArrayParamValue('eligibilities')
            dispatch(setSelectedEligibilities(eligibilities.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligibilities])

    return (
        <Autocomplete
            options={eligibilities ?? []}
            value={selectedEligibilities}
            onChange={(_, value) => dispatch(setSelectedEligibilities(value))}
            getOptionLabel={(e) => e.description}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Kund'
                error={hasFailedToLoadEligibilities}
                helperText={hasFailedToLoadEligibilities ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingEligibilities || hasFailedToLoadEligibilities || isSearching}
        />
    )
}