import { FormikHelpers as FormikActions, FormikProps, FormikValues } from 'formik'
import { AxiosError } from 'axios'
import { isEmpty, isNil } from 'ramda'
import { ISelectOption } from '@local/src/App.types'

interface ScrollEvent {
  target: { scrollTop: number; scrollHeight: number; offsetHeight: number }
}

export const scrolledToBottomAndNotLazyLoading =
  (lazy: boolean) =>
  ({ target: { scrollTop, scrollHeight, offsetHeight } }: ScrollEvent) =>
    !lazy && scrollTop > scrollHeight - offsetHeight - 40

export const yes = (v: boolean) => (v ? 'Ja' : '')
export const yesNo = (v: boolean) => (v ? 'Ja' : 'Nej')
export const yesNoNotSet = (v: string) => {
  if (v === null) {
    return 'Inget aktivt val gjort'
  }
  if (v === 'true') {
    return 'Ja'
  }
  return 'Nej'
}

export const formatUtcDate = (utcDate: string) => (utcDate ? utcDate.substr(0, 10) : '')

export const formatOrgNr = (n: string) => `${n?.substr(-10, 6)}-${n?.substr(-4, 4)}`

export const getTranslatedRole = (roles: string[]) =>
  roles.includes('orgSuperuser')
    ? 'ORG-superuser'
    : roles.includes('orgAdministrator')
      ? 'ORG-företagsadmin'
      : roles.includes('orgUser')
        ? 'ORG-användare'
        : ''

export const formatCompanyTypeName = (name: string) => {
  switch (name) {
    case 'HUVUDKONTOR':
      return 'HK'
    case 'ARBETSPLATS':
      return 'AP'
    default:
      return name
  }
}

export const SAPFormat = (guid: string) => guid.replace(/-/g, '').toUpperCase()
export const convertToMSek = (amount: number) => prettyPrintAmount(amount / 1000000)
export const convertPayrollToMSek = (payroll: number[]) => (payroll ? payroll.map((x) => x / 1000000) : null)
export const convertToNumberArray = (array: unknown[]) => (array ? array.map((x) => Number(x)) : null)

export const convertMSekToSek = (amount: number) => amount * 1000000

export const prettyPrintAmount = (amount: number) => Math.round(amount).toLocaleString('en').replace(/,/g, ' ')

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export const encodeRFC5987ValueChars = (fileName: string) =>
  encodeURIComponent(fileName)
    // Note that although RFC3986 reserves "!", RFC5987 does not,
    // so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987,
    // so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape)

export const trimPathInformation = (fileName: string) => fileName.substring(fileName.lastIndexOf('/') + 1)

export const fileNameValid = (fileName: string) => fileName.length < 257

export const isOrgNumber = (input: string) => /^(\d{6})(-*)(\d{4})$/.test(input.trim())

export const isSliderValueValid = (value: number[], maxAllowedValue: number) => {
  const leftValue = Number(value[0])
  const rightValue = Number(value[1])

  return leftValue >= 0 && rightValue <= maxAllowedValue && leftValue <= rightValue
}

export const customSetFieldValue =
  (_setFieldValue: FormikActions<FormikValues>['setFieldValue'], fieldName: string) =>
  (value: ISelectOption[] | number[]): void => {
    void _setFieldValue(fieldName, value)
  }

const statusCodeAsText = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return 'Du saknar behörighet.'
    case 403:
      return 'Du saknar behörighet.'
    case 404:
      return 'Resursen hittades inte.'
    default:
      return 'Ett oväntat systemfel inträffade.'
  }
}

export const parseErrorMessage = (error: AxiosError) => {
  const axiosError = error?.response?.data['userFacingErrorMessage']
  return axiosError ?? statusCodeAsText(error?.response?.status)
}

export const removeTrailingSlash = (url: string): string => url.replace(/\/$/, '')

export const isNotNullOrEmpty = (variable: unknown): boolean => !isNil(variable) && !isEmpty(variable)

export const getValidationError = (formikProps: FormikProps<unknown>, formProp: string): string =>
  formikProps.errors && formikProps.touched[formProp] && formikProps.errors[formProp]

export const formControlHasError = (formikProps: FormikProps<unknown>, formControlName: string): boolean =>
  formikProps.errors[formControlName] !== undefined && formikProps.touched[formControlName] !== undefined