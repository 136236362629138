import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/Utils/ConfigService'
import { getIdToken } from '@trr/app-shell-data'

const { API_URL } = getConfig()

export const organisationsregistretApi = createApi({
  reducerPath: 'organisationsregistretApi',
  refetchOnMountOrArgChange: 30,
  keepUnusedDataFor: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getIdToken()
      headers.set('Authorization', `Bearer ${token}`)
      return headers
    },
  }),
  endpoints: () => ({}),
})
