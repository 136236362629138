import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'
import { createEmail } from '@local/Blocks/CreateEmail/CreateEmail.actions'
import { deleteContact } from '@local/Scenes/Company/Company.actions'

import { displayMyContacts } from '../../Contacts.actions'

import AdvancedSearchResult from './ContactAdvancedSearchResult'

const mapStateToProps = (state: RootState) => ({
  searchResults: state.contacts.loading ? [] : state.contacts.contactsData,
  loading: state.contacts.loading,
  createEmailState: state.createEmail.createEmailState,
})

const mapDispatchToProps = {
  deleteContact,
  displayMyContacts,
  createEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchResult)
