import { AutocompleteChangeReason, CircularProgress, Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { debounce } from '@mui/material/utils'

interface AutocompleteProps {
  label: string
  options: unknown[]
  value: unknown | unknown[]
  multiple?: boolean
  error?: boolean
  helperText?: string
  loading?: boolean
  testSelector?: string
  fullWidth?: boolean
  disabled?: boolean
  noOptionsText?: string
  emptySearchText?: string
  getOptionLabel: (option: unknown) => string
  getOptionKey: (option: unknown) => string
  inputValueChanged?: (value: string) => void
  onChange?: (value: unknown | unknown[]) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement, Element>) => void
}

const Autocomplete = ({
  label,
  options,
  value,
  multiple = false,
  error = false,
  helperText,
  loading = false,
  fullWidth = true,
  disabled = false,
  testSelector,
  noOptionsText = 'Inga alternativ',
  emptySearchText = 'Inga alternativ',
  getOptionLabel,
  getOptionKey,
  inputValueChanged,
  onChange,
  onBlur,
}: AutocompleteProps): JSX.Element => {

  const [inputValue, setInputValue] = useState('')

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      if (multiple) onChange([])
      else onChange(null)
      return
    }
    onChange(value)
  }

  const debounceValue = useMemo(() =>
    debounce((request: string, callback: (value: string) => void) => {
      callback(request)
    }, 400), []
  )

  useEffect(() => {
    if (inputValue?.length > 0) {
      inputValueChanged && debounceValue(inputValue, inputValueChanged)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <MuiAutocomplete
      disabled={disabled}
      fullWidth={fullWidth}
      multiple={multiple}
      defaultValue={multiple ? [] : null}
      value={value || null}
      loading={loading}
      options={loading ? [] : options}
      onChange={handleChange}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      getOptionLabel={getOptionLabel}
      autoHighlight
      isOptionEqualToValue={(option, value) => getOptionKey(option) === getOptionKey(value)}
      renderInput={(params) => (
        <TextField
          data-testid={testSelector}
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={getOptionKey(option)}>{getOptionLabel(option)}</li>
      )}
      noOptionsText={inputValue?.length === 0 ? emptySearchText : noOptionsText}
      onBlur={event => onBlur?.(event)}
    />
  )
}

export default Autocomplete