import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactsSearch, MyContactsSearch } from './Contacts.types'

export interface AdvancedSearchResultItem {
  name: string
}

export interface IContactsState {
  loading: boolean
  myContactsLoading: boolean
  showOnlyActiveContacts: boolean
  showOnlyMainContacts: boolean
  myContactsData?: MyContactsSearch[]
  contactsData?: ContactsSearch[]
  allContactsData?: AdvancedSearchResultItem[]
}

export const initialState: IContactsState = {
  loading: false,
  myContactsLoading: false,
  showOnlyActiveContacts: true,
  showOnlyMainContacts: false,
  myContactsData: [],
  contactsData: [],
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    deleteContactStarted: state => {
      state.myContactsLoading = true
    },
    deleteContactSuccess: state => {
      state.myContactsLoading = false
    },
    deleteContactFailed: state => {
      state.myContactsLoading = false
    },
    searchMyContactsInitiate: state => {
      state.myContactsLoading = true
    },
    searchMyContactsSuccess: (state, action: PayloadAction<{ result: MyContactsSearch[] }>) => {
      state.myContactsData = action.payload.result
      state.myContactsLoading = false
    },
    searchMyContactsFailure: state => {
      state.myContactsData = []
      state.myContactsLoading = false
    },
    searchContactsInitiate: state => {
      state.loading = true
    },
    searchContactsSuccess: (state, action: PayloadAction<{ result: ContactsSearch[] }>) => {
      state.contactsData = action.payload.result
      state.loading = false
    },
    searchContactsFailure: state => {
      state.contactsData = []
      state.loading = false
    },
  },
})

export const { actions, reducer: contactsReducer } = contactsSlice
export const {
  deleteContactStarted,
  deleteContactSuccess,
  deleteContactFailed,
  searchMyContactsSuccess,
  searchContactsInitiate,
  searchMyContactsFailure,
  searchMyContactsInitiate,
  searchContactsSuccess,
  searchContactsFailure,
} = contactsSlice.actions
export default contactsReducer
