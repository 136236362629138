import React, { useEffect, useState } from 'react'
import { IconButton, Paper, TextField, InputAdornment, useTheme, useMediaQuery } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'

interface ISearchField {
  label: string
  search: (searchText: string) => void
  value: string
  disabled?: boolean
}

const SearchField = ({
  label,
  search,
  value,
  disabled = false,
}: ISearchField): JSX.Element => {
  const [searchText, setSearchText] = useState(value)
  const [showClearButton, setShowClearButton] = useState(value.length > 0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setSearchText(value)
    setShowClearButton(value.length > 0)
  }, [value])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    setShowClearButton(false)
  }

  const handleSearchButtonClick = () => {
    search(searchText)
    setShowClearButton(true)
  }

  const handleClearButtonClick = () => {
    search('')
    setSearchText('')
    setShowClearButton(false)
  }

  const searchOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      search(searchText)
      setShowClearButton(true)
    }
  }

  const getSearchFieldIcon = () => {
    if (!showClearButton) {
      return (
        <InputAdornment position='end'>
          <IconButton
            aria-label='search'
            onClick={handleSearchButtonClick}
            disabled={disabled}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )
    }

    if (showClearButton) {
      return (
        <InputAdornment position='end'>
          <IconButton
            aria-label='clear search'
            onClick={handleClearButtonClick}
            disabled={disabled}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <Paper elevation={0} sx={{ maxWidth: '950px' }}>
      <TextField
        InputLabelProps={isMobile ? { shrink: true } : {}}
        label={label}
        variant='outlined'
        disabled={disabled}
        onChange={handleSearchChange}
        onEmptied={handleClearButtonClick}
        onKeyDown={searchOnEnterKeyDown}
        value={searchText}
        fullWidth
        InputProps={{
          endAdornment: getSearchFieldIcon(),
        }}
        inputProps={{
          'data-testid': 'search-field',
        }}
      />
    </Paper>
  )
}

export default SearchField
