import React, { useState } from 'react'
import { ICreateEmailRecipients } from '@local/Blocks/CreateEmail/CreateEmail.types'
import CreateEmail from '@local/Blocks/CreateEmail'
import { useHistory } from 'react-router-dom'
import { contactDetailsUrl } from '@local/src/basename'
import { GridColumnVisibilityModel, GridEventListener, GridRowId, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { Alert, Typography } from '@mui/material'
import Loading from '@local/src/Components/Loading/Loading'
import { FetchingState } from '@local/src/App.types'

import { ContactColumnVisibility, ContactsSearch } from '../../Contacts.types'

import { contactColumns } from './contactColumns'

export const contactColumnVisibilityInitialState: ContactColumnVisibility = {
  email: true,
  fullName: true,
  mobileNumber: true,
  roleLevelOneRoleLevel1Text: true,
  roleLevelTwoRoleLevel2Text: true,
  statusStatusText: true,
  workplaceName: true,
}
export interface IAdvancedSearchResult {
  createEmail?: (subject: string, recipients: ICreateEmailRecipients[]) => void
  loading?: boolean
  searchResults: ContactsSearch[]
  createEmailState?: FetchingState
}

const SEARCH_MAX = 500

const AdvancedSearchResult = ({ createEmail, loading, searchResults, createEmailState }: IAdvancedSearchResult) => {
  const history = useHistory()
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `org.contacts.columnVisibility`,
    contactColumnVisibilityInitialState
  )
  const [sortModel, setSortModel] = useLocalStorage('org.contacts.sortModel', [{ field: 'fullName', sort: 'asc' }])
  const [visibleRowIds, setVisibleRowIds] = useState<GridRowId[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])

  const handleSetColumnVisibility = (model: GridColumnVisibilityModel) =>
    setColumnVisibility(model as ContactColumnVisibility)

  const navigateToContact: GridEventListener<'rowClick'> = (params: GridRowParams<ContactsSearch>) => {
    params.row && history.push(contactDetailsUrl(params.row.workplaceId, params.row.contactId))
  }

  if (loading) return <Loading />

  const getRecipients = (): ICreateEmailRecipients[] => {
    return searchResults
      .reduce((accumulator: ContactsSearch[], current: ContactsSearch) => {
        selectedRowIds.some(rowId => current.contactId === rowId) && accumulator.push(current)
        return accumulator
      }, [])
      .map((value: ContactsSearch): ICreateEmailRecipients => ({ id: value.contactId, emailAddress: value.email }))
  }

  return (
    <>
      <Typography variant="h4" marginBottom={2}>{`Sökresultat (${visibleRowIds.length})`}</Typography>

      {searchResults && searchResults.length >= SEARCH_MAX && (
        <Alert severity="warning" sx={{ marginBottom: 1 }}>
          {`Maximalt antal sökresultat (${SEARCH_MAX}) uppnått. Förfina sökningen om du vill vara
          säker på att få med alla relevanta resultat`}
        </Alert>
      )}

      <DataGridWrapper
        rows={searchResults}
        columns={contactColumns}
        columnVisibilityModel={columnVisibility}
        columnVisibilityModelChange={handleSetColumnVisibility}
        getRowId={(row: ContactsSearch) => row.contactId}
        labelRowsPerPage="Kontaktpersoner per sida"
        onRowClick={navigateToContact}
        sortModel={sortModel as GridSortModel}
        setSortModel={setSortModel}
        getVisibleRowIds={setVisibleRowIds}
        showDefaultToolbar
        checkboxSelection
        getSelectedRowIds={setSelectedRowIds}
        customActions={
          <CreateEmail recipients={getRecipients()} createEmail={createEmail} createEmailState={createEmailState} />
        }
      />
    </>
  )
}

export default AdvancedSearchResult
