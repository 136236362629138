import React, { useState } from 'react'
import { Stack, TextField, Box, Slider, IconButton, Popover, Typography, InputAdornment } from '@mui/material'
import { Expand } from '@mui/icons-material'



export interface IRangeFilterProps {
    label: string
    from: number
    to: number
    min: number
    max: number
    onChange: (from: number, to: number) => void
    unit?: string
    disabled?: boolean
}

export const RangeFilter = ({ label, from, to, min, max, onChange, unit, disabled }: IRangeFilterProps) => {
    const [sliderParent, setSliderParent] = useState<Element>(null);

    const handleOnChange = (value: string, fromOrTo: 'from' | 'to') => {
        if (isNaN(Number(value)))
            return

        if (fromOrTo === 'from')
            onChange(Number(value), to)
        else if (fromOrTo === 'to')
            onChange(from, Number(value))
    }

    const orderFromAndTo = () => {
        const min = Math.min(from, to)
        const max = Math.max(from, to)
        onChange(min, max)
    }

    return (
        <Stack direction='column' rowGap={0.5}>
            <Typography fontSize={12.5}>{label}</Typography>
            <Stack direction='row' justifyContent='space-between' columnGap={2}>
                <TextField
                    value={from}
                    onChange={e => handleOnChange(e.currentTarget.value, 'from')}
                    onBlur={orderFromAndTo}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            e.currentTarget.blur()
                    }}
                    inputProps={{ style: { textAlign: 'center', height: 0 } }}
                    InputProps={{
                        endAdornment: unit !== undefined && <InputAdornment position='end'>{unit}</InputAdornment>,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                e.currentTarget.blur()
                        }
                    }}
                    onFocus={e => e.target.select()}
                    disabled={disabled}
                />

                <IconButton size='small' color='primary' onClick={e => setSliderParent(e.currentTarget)} disabled={disabled} >
                    <Expand sx={{ transform: 'rotate(90deg)' }} />
                </IconButton>
                <Popover
                    open={sliderParent !== null}
                    onClose={() => setSliderParent(null)}
                    anchorEl={sliderParent}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Box width={300} paddingX={3} paddingY={1} display='flex'>
                        <Slider
                            min={min}
                            max={max}
                            value={[from, to]}
                            disableSwap
                            onChange={(_, values: number[]) => {
                                onChange(values[0], values[1])
                            }}
                            disabled={disabled} />
                    </Box>
                </Popover>

                <TextField
                    value={to}
                    onChange={e => handleOnChange(e.currentTarget.value, 'to')}
                    onBlur={orderFromAndTo}
                    inputProps={{ style: { textAlign: 'center', height: 0 } }}
                    InputProps={{
                        endAdornment: unit !== undefined && <InputAdornment position='end'>{unit}</InputAdornment>,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                e.currentTarget.blur()
                        }
                    }}
                    onFocus={e => e.target.select()}
                    disabled={disabled}
                />
            </Stack>
        </Stack>
    )
}