import React, { useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { ICompanyManager, ISelectOption } from '@local/src/App.types'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  createNewProject,
  deleteProject,
  getProjectById,
  getProjectStatuses,
  updateProject,
} from '@local/Scenes/Company/CompanyTabs/Projects/Projects.actions'
import { fetchContactsData } from '@local/Scenes/Company/Company.actions'
import { companyProjectUrl, projectDetailsUrl } from '@local/src/basename'
import { resetProject } from '@local/Scenes/Company/CompanyTabs/Projects/Projects.reducers'
import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import Loading from '@local/src/Components/Loading/Loading'
import { formControlHasError, getValidationError } from '@local/src/Utils/helpers'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { useFetchArbetsstallen } from '@local/src/hooks'
import { useGetCompanyManagersQuery, useLazyGetCompanyDetailsQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { useUser } from '@trr/app-shell-data'

import { HandleProjectFormValues, IProjectStatusSelectOption } from '../Projects.types'
import { createProjectInProgressSelector, deleteProjectInProgressSelector, projectSaveFailedSelector, projectSelector, projectStatusesSelector, updateProjectInProgressSelector } from '../Projects.selectors'
import { companyContactsSelector, companySelector } from '../../../Company.selectors'

import { projectValidation } from './HandleProject.validation'
import { convertCreateFormValues, convertEditFormValues, getInitialValuesForCreate, getInitialValuesForEdit } from './HandleProject.helpers'
import DeleteProjectModal from './DeleteProjectModal/DeleteProjectModal'

const HandleProject = () => {

  const history = useHistory()
  const { companyGuid, projectGuid } = useParams<{ companyGuid: string; projectGuid: string }>()
  const creatingNewProject = history.location.pathname.endsWith('skapa')
  const dispatch = useAppDispatch()

  const { email: userEmail } = useUser()
  const project = useStateSelector(projectSelector)
  const contacts = useStateSelector(companyContactsSelector)
  const projectStatuses = useStateSelector(projectStatusesSelector)
  const updateProjectInProgress = useStateSelector(updateProjectInProgressSelector)
  const saveFail = useStateSelector(projectSaveFailedSelector)
  const createProjectInProgress = useStateSelector(createProjectInProgressSelector)
  const deleteProjectInProgress = useStateSelector(deleteProjectInProgressSelector)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [arbetsstalleSearchCriteria, setArbetsstalleSearchCriteria] = useState('')
  const { arbetsstallenLoading, arbetstallenOptions } = useFetchArbetsstallen(arbetsstalleSearchCriteria, companyGuid)

  const [fetchCompany, { data: company, isFetching: isLoadingCompany }] = useLazyGetCompanyDetailsQuery()
  const { data: companyManagersFromQuery, isLoading: isLoadingCompanyManagers } = useGetCompanyManagersQuery();


  useEffect(() => {
    if (companyGuid)
      void fetchCompany(companyGuid)
  }, [companyGuid])

  useEffect(() => {
    if (!creatingNewProject) {
      dispatch(getProjectById(companyGuid, projectGuid))
    }
    dispatch(fetchContactsData(companyGuid))
    dispatch(getProjectStatuses())

    return () => {
      dispatch(resetProject())
    }
  }, [companyGuid, creatingNewProject, dispatch, projectGuid])

  const prevUpdateProjectInProgress = useRef(updateProjectInProgress)

  useEffect(() => {
    const finishedUpdating = prevUpdateProjectInProgress.current && !updateProjectInProgress && !saveFail
    if (finishedUpdating) {
      history.push(projectDetailsUrl(companyGuid, projectGuid))
    }

    prevUpdateProjectInProgress.current = updateProjectInProgress
  }, [companyGuid, history, projectGuid, saveFail, updateProjectInProgress])

  const prevCreateProjectInProgress = useRef(createProjectInProgress)

  useEffect(() => {
    const finishedCreating = prevCreateProjectInProgress.current && !createProjectInProgress && !saveFail
    if (finishedCreating) {
      history.push(companyProjectUrl(companyGuid))
    }

    prevCreateProjectInProgress.current = createProjectInProgress
  }, [companyGuid, history, projectGuid, createProjectInProgress, saveFail])

  const prevDeleteProjectInProgress = useRef(deleteProjectInProgress)

  useEffect(() => {
    const finishedDeleting = prevDeleteProjectInProgress.current && !deleteProjectInProgress
    if (finishedDeleting) {
      history.push(companyProjectUrl(companyGuid))
    }

    prevDeleteProjectInProgress.current = deleteProjectInProgress
  }, [companyGuid, deleteProjectInProgress, history])

  const disableContact = () => !creatingNewProject && companyGuid !== project.huvudForetag.foretagId



  const getMappedContacts = () => {
    return contacts
      .filter(contacts => contacts.status.statusId === '0')
      .map(contact => ({
        value: contact.contactId,
        label: contact.fullName,
      }))
  }

  if (!company || !projectStatuses?.length || prevUpdateProjectInProgress.current || (!creatingNewProject && !project) || isLoadingCompanyManagers || isLoadingCompany) {
    return <Loading />
  }

  const companyManagers = companyManagersFromQuery.map(cm => ({ value: cm.id, label: cm.fullName, email: cm.email }) as ICompanyManager)

  const getLoggedInUser = (): ISelectOption => {
    const loggedInUser = companyManagers
      .filter(cm => cm.email === userEmail)
      .map(user => ({ value: user.value, label: user.label }))

    return {
      value: loggedInUser[0].value,
      label: loggedInUser[0].label,
    }
  }

  const initialValues: HandleProjectFormValues = creatingNewProject
    ? getInitialValuesForCreate(getLoggedInUser(), projectStatuses)
    : getInitialValuesForEdit(project)

  const handleCancelButton = () => history.goBack()
  const handleDeleteProject = () => {
    setModalIsOpen(false)
    dispatch(deleteProject(companyGuid, project.projektId))
  }

  const onSubmit = (values: HandleProjectFormValues) => {
    if (creatingNewProject) {
      const project = convertCreateFormValues(values, companyGuid)
      dispatch(createNewProject(project))
    } else {
      const updatedProject = convertEditFormValues(values, project, companyGuid)
      dispatch(updateProject(updatedProject.huvudForetag.foretagId, updatedProject.projektId, updatedProject))
    }
  }

  return (
    <Stack maxWidth={752}>
      <Box display='flex' flexDirection='column' gap={1} marginBottom={3}>
        <Typography variant='h5'>{creatingNewProject ? `Skapa projekt för ${company.fullName}` : 'Redigera projekt'}</Typography>
        {!creatingNewProject && (
          <Typography variant='body1'>{`${project.projektnummer} - ${project.projektnamn}`}</Typography>
        )}
      </Box>

      {saveFail && (
        <Alert severity="error">Fel vid sparande av projekt</Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={projectValidation} onSubmit={onSubmit} validateOnMount>
        {(formikProps: FormikProps<HandleProjectFormValues>) => (
          <Stack component='form' onSubmit={formikProps.handleSubmit}>
            <Box display='flex' flexDirection='column' maxWidth={480} gap={3}>
              <TextField
                label='Projektnamn'
                name='projektnamn'
                fullWidth
                value={formikProps.values.projektnamn}
                onChange={(event) => void formikProps.setFieldValue('projektnamn', event.target.value)}
                onBlur={(event) => formikProps.handleBlur(event)}
                error={formControlHasError(formikProps, 'projektnamn')}
                helperText={getValidationError(formikProps, 'projektnamn')}
              />

              <TextField
                label='Beskrivning'
                multiline
                fullWidth
                minRows={4}
                value={formikProps.values.beskrivning}
                onChange={(event) => void formikProps.setFieldValue('beskrivning', event.target.value)}
                onBlur={() => void formikProps.setFieldTouched('beskrivning')}
                error={formControlHasError(formikProps, 'beskrivning')}
                helperText={getValidationError(formikProps, 'beskrivning')}
              />

              <Autocomplete
                label='Projektägare'
                value={formikProps.values.projektagare}
                options={companyManagers}
                getOptionLabel={(value: ICompanyManager) => value.label}
                getOptionKey={(value: ICompanyManager) => value.value}
                onChange={(event: ICompanyManager) => void formikProps.setFieldValue('projektagare', event)}
                onBlur={() => void formikProps.setFieldTouched('projektagare')}
                error={formControlHasError(formikProps, 'projektagare')}
                helperText={getValidationError(formikProps, 'projektagare')}
              />

              <Dropdown
                label='Projektstatus'
                options={projectStatuses}
                value={formikProps.values.projektstatus.value}
                getOptionLabel={(value: IProjectStatusSelectOption) => value.label}
                getOptionValue={(value: IProjectStatusSelectOption) => value.value}
                onChange={(event) => {
                  void formikProps.setFieldValue('projektstatus', projectStatuses.find(val => val.value === event.target.value))
                }}
              />

              <Autocomplete
                label="Företagets kontaktperson"
                fullWidth
                value={formikProps.values.kontaktperson}
                onChange={(event) => void formikProps.setFieldValue('kontaktperson', event)}
                onBlur={() => void formikProps.setFieldTouched('kontaktperson')}
                getOptionLabel={(value: ISelectOption) => value.label}
                getOptionKey={(value: ISelectOption) => value.value}
                options={getMappedContacts()}
                disabled={disableContact()}
                helperText='Frivilligt'
              />
            </Box>

            <Box marginY={3}>
              <Typography variant='h6'>Projektperiod</Typography>
              <Typography variant='body1'>Perioden som projektet pågår.</Typography>
            </Box>

            <Box display='flex' gap={3} maxWidth={480}>
              <DesktopDatePicker
                label='Projektstart'
                value={new Date(formikProps.values.projektStart)}
                onChange={(value) => {
                  void formikProps.setFieldTouched('projektStart')
                  void formikProps.setFieldValue('projektStart', value)
                }}
                onClose={() => void formikProps.setFieldTouched('projektStart')}
                slotProps={{ textField: { fullWidth: true, onBlur: () => void formikProps.setFieldTouched('projektStart'), error: formControlHasError(formikProps, 'projektStart'), helperText: getValidationError(formikProps, 'projektStart') } }}
              />
              <DesktopDatePicker
                label='Projektslut'
                value={new Date(formikProps.values.projektSlut)}
                onChange={(value) => {
                  void formikProps.setFieldTouched('projektSlut')
                  void formikProps.setFieldValue('projektSlut', value)
                }}
                onClose={() => void formikProps.setFieldTouched('projektSlut')}
                slotProps={{ textField: { fullWidth: true, onBlur: () => void formikProps.setFieldTouched('projektSlut'), error: formControlHasError(formikProps, 'projektSlut'), helperText: getValidationError(formikProps, 'projektSlut') } }}
              />
            </Box>

            <Box marginY={3}>
              <Typography variant='h6'>Kopplingsperiod</Typography>
              <Typography variant='body1'>Omställningskunder med godkänd ansökan kommer under vald kopplingsperiod automatiskt hamna i projektet.</Typography>
            </Box>

            <Box display='flex' gap={3} maxWidth={480}>
              <DesktopDatePicker
                label='Kopplingsperiod start'
                defaultValue={new Date(formikProps.values.klientkopplingStart)}
                onChange={(value) => {
                  void formikProps.setFieldTouched('klientkopplingStart')
                  void formikProps.setFieldValue('klientkopplingStart', value)
                }}
                onClose={() => void formikProps.setFieldTouched('klientkopplingStart')}
                slotProps={{ textField: { fullWidth: true, onBlur: () => void formikProps.setFieldTouched('klientkopplingStart'), error: formControlHasError(formikProps, 'klientkopplingStart'), helperText: getValidationError(formikProps, 'klientkopplingStart') } }}
              />
              <DesktopDatePicker
                label='Kopplingsperiod slut'
                defaultValue={new Date(formikProps.values.klientkopplingSlut)}
                onChange={(value) => {
                  void formikProps.setFieldTouched('klientkopplingSlut')
                  void formikProps.setFieldValue('klientkopplingSlut', value)
                }}
                onClose={() => void formikProps.setFieldTouched('klientkopplingSlut')}
                slotProps={{ textField: { fullWidth: true, onBlur: () => void formikProps.setFieldTouched('klientkopplingSlut'), error: formControlHasError(formikProps, 'klientkopplingSlut'), helperText: getValidationError(formikProps, 'klientkopplingSlut') } }}
              />
            </Box>

            <Box marginY={3}>
              <Typography variant='h6'>Arbetsställen</Typography>
              <Typography variant='body1' marginBottom={4}>{`${company.fullName}, ${company.visitingAddress.street}, ${company.visitingAddress.town}`}</Typography>
              <Typography variant='body1'>Om fler arbetsställen ingår i projektet lägger du till dem här.</Typography>
            </Box>

            <Box maxWidth={480}>
              <Autocomplete
                label='Arbetsställen'
                helperText='Frivilligt'
                emptySearchText='Skriv för att hämta arbetsställen'
                multiple
                options={arbetstallenOptions}
                loading={arbetsstallenLoading}
                inputValueChanged={(value: string) => setArbetsstalleSearchCriteria(value)}
                getOptionLabel={(value: ISelectOption) => value.label}
                getOptionKey={(value: ISelectOption) => value.value}
                value={formikProps.values.foretag}
                onBlur={() => void formikProps.setFieldTouched('foretag')}
                onChange={(option) => { void formikProps.setFieldValue('foretag', option) }}
              />
            </Box>

            <Box
              display='flex'
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent='flex-start'
              gap={2}
              marginTop={3}
            >
              <Button type='submit' disabled={deleteProjectInProgress}>Spara</Button>
              {!creatingNewProject && (
                <Button
                  variant='outlined'
                  onClick={() => setModalIsOpen(true)}
                  disabled={deleteProjectInProgress || createProjectInProgress}
                >
                  {deleteProjectInProgress ? (
                    <CircularProgress size='1rem' />
                  ) : (
                    'Ta bort projekt'
                  )}
                </Button>
              )}
              <Button variant='outlined' onClick={handleCancelButton} disabled={deleteProjectInProgress}>Avbryt</Button>
            </Box>

            <DeleteProjectModal
              open={modalIsOpen}
              onCancel={() => setModalIsOpen(false)}
              onSubmit={handleDeleteProject}
            />
          </Stack>
        )}
      </Formik>
    </Stack>
  )
}

export default HandleProject
