import { ICounty } from "@local/src/Models/Domain/County.model";
import { IMunicipality } from "@local/src/Models/Domain/Municipality.model";
import { ITrrIndustry } from "@local/src/Models/Domain/TrrIndustry.model";
import { IWorkplaceEligibility } from "@local/src/Models/Domain/WorkplaceEligibility.model";
import { IWorkplaceManager } from "@local/src/Models/Domain/WorkplaceManager.model";
import { IWorkplaceStatus } from "@local/src/Models/Domain/WorkplaceStatus.model";
import { IWorkplaceType } from "@local/src/Models/Domain/WorkplaceType.model";
import { IRange } from "@local/src/Models/Misc/Range.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WHITE_COLLAR_RANGE_MIN, WHITE_COLLAR_RANGE_MAX, PAYROLL_RANGE_MIN, PAYROLL_RANGE_MAX } from "./WorkplaceSearchFilter";

export interface WorkplaceSearchStore {
    nameOrgNumber: string
    selectedCounties: ICounty[]
    selectedMunicipalities: IMunicipality[]
    selectedWorkplaceTypes: IWorkplaceType[]
    selectedTrrIndustries: ITrrIndustry[]
    selectedNationalManagers: IWorkplaceManager[]
    selectedLocalManagers: IWorkplaceManager[]
    selectedLocalContacts: IWorkplaceManager[]
    selectedEligibilities: IWorkplaceEligibility[]
    selectedWorkplaceStatuses: IWorkplaceStatus[]
    whiteCollarRange: IRange,
    payrollRange: IRange,
    isLocallyImportant: boolean,
    loadedFilterFromUrl: boolean
}

export const initialState: WorkplaceSearchStore = {
    nameOrgNumber: '',
    selectedCounties: [],
    selectedMunicipalities: [],
    selectedWorkplaceTypes: [],
    selectedTrrIndustries: [],
    selectedNationalManagers: [],
    selectedLocalManagers: [],
    selectedLocalContacts: [],
    selectedEligibilities: [],
    selectedWorkplaceStatuses: [],
    whiteCollarRange: { from: WHITE_COLLAR_RANGE_MIN, to: WHITE_COLLAR_RANGE_MAX },
    payrollRange: { from: PAYROLL_RANGE_MIN, to: PAYROLL_RANGE_MAX },
    isLocallyImportant: false,
    loadedFilterFromUrl: false
}

const workplaceSearchSlice = createSlice({
    name: 'workplaceSearch',
    initialState,
    reducers: {
        setNameOrgNumber: (state, action: PayloadAction<string>) => {
            state.nameOrgNumber = action.payload
        },
        setSelectedCounties: (state, action: PayloadAction<ICounty[]>) => {
            state.selectedCounties = action.payload
        },
        setSelectedMunicipalities: (state, action: PayloadAction<IMunicipality[]>) => {
            state.selectedMunicipalities = action.payload
        },
        setSelectedWorkplaceTypes: (state, action: PayloadAction<IWorkplaceType[]>) => {
            state.selectedWorkplaceTypes = action.payload
        },
        setSelectedTrrIndustries: (state, action: PayloadAction<ITrrIndustry[]>) => {
            state.selectedTrrIndustries = action.payload
        },
        setSelectedNationalManagers: (state, action: PayloadAction<IWorkplaceManager[]>) => {
            state.selectedNationalManagers = action.payload
        },
        setSelectedLocalManagers: (state, action: PayloadAction<IWorkplaceManager[]>) => {
            state.selectedLocalManagers = action.payload
        },
        setSelectedLocalContacts: (state, action: PayloadAction<IWorkplaceManager[]>) => {
            state.selectedLocalContacts = action.payload
        },
        setSelectedEligibilities: (state, action: PayloadAction<IWorkplaceEligibility[]>) => {
            state.selectedEligibilities = action.payload
        },
        setSelectedWorkplaceStatuses: (state, action: PayloadAction<IWorkplaceStatus[]>) => {
            state.selectedWorkplaceStatuses = action.payload
        },
        setWhiteCollarRange: (state, action: PayloadAction<IRange>) => {
            state.whiteCollarRange = action.payload
        },
        setPayrollRange: (state, action: PayloadAction<IRange>) => {
            state.payrollRange = action.payload
        },
        setIsLocallyImportant: (state, action: PayloadAction<boolean>) => {
            state.isLocallyImportant = action.payload
        },
        reset: () => initialState,
        loadedFilterFromUrl: (state) => { state.loadedFilterFromUrl = true }
    }
})



export const { actions, reducer: workplaceSearchReducer } = workplaceSearchSlice

export const {
    setNameOrgNumber,
    setSelectedCounties,
    setSelectedMunicipalities,
    setSelectedWorkplaceTypes,
    setSelectedTrrIndustries,
    setSelectedNationalManagers,
    setSelectedLocalManagers,
    setSelectedLocalContacts,
    setSelectedEligibilities,
    setSelectedWorkplaceStatuses,
    setWhiteCollarRange,
    setPayrollRange,
    setIsLocallyImportant,
    reset,
    loadedFilterFromUrl
} = workplaceSearchSlice.actions

export default workplaceSearchReducer