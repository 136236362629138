import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ConfirmationModal from '@local/Components/ConfirmationModal/ConfirmationModal'
import CreateEmail from '@local/Blocks/CreateEmail'
import { ICreateEmailRecipients } from '@local/Blocks/CreateEmail/CreateEmail.types'
import { contactDetailsUrl, contactDetailsEditUrl } from '@local/src/basename'
import { GridColumnVisibilityModel, GridEventListener, GridRowId, GridRowParams, GridSortModel, MuiEvent } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { Alert, Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { FetchingState } from '@local/src/App.types'
import Loading from '@local/src/Components/Loading/Loading'

import { ContactColumnVisibility, MyContactsSearch } from '../Contacts.types'

import { contactSearchResultColumns } from './ContactSearchResult.columns'

export const contactColumnVisibilityInitialState: ContactColumnVisibility = {
  email: true,
  fullName: true,
  mobileNumber: true,
  roleLevelOneRoleLevel1Text: true,
  roleLevelTwoRoleLevel2Text: true,
  statusStatusText: true,
  workplaceName: true,
}

export interface ContactSearchResultProps {
  deleteContact?: (workplaceId: string, contactId: string) => void
  createEmail?: (subject: string, recipients: ICreateEmailRecipients[]) => void
  loading?: boolean
  myContactsData: MyContactsSearch[]
  showOnlyActiveContacts: boolean
  showOnlyMainContacts: boolean
  createEmailState?: FetchingState
}

const SEARCH_MAX = 500

const ContactSearchResult = ({
  deleteContact,
  createEmail,
  loading,
  myContactsData,
  showOnlyActiveContacts,
  showOnlyMainContacts,
  createEmailState,
}: ContactSearchResultProps) => {

  const [columnVisibility, setColumnVisibility] = useLocalStorage(`org.myContacts.columnVisibility`, contactColumnVisibilityInitialState)
  const [sortModel, setSortModel] = useLocalStorage('org.myContacts.sortModel', [{ field: 'fullName', sort: 'asc' }])
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [contactIdToDelete, setContactIdToDelete] = useState<string>(null)
  const [contactWorkplaceId, setContactWorkplaceId] = useState<string>(null)
  const [isShowingOnlyActiveContacts, setIsShowingOnlyActiveContacts] = useState(showOnlyActiveContacts)
  const [isShowingOnlyMainContacts, setIsShowingOnlyMainContacts] = useState(showOnlyMainContacts)
  const [visibleRowIds, setVisibleRowIds] = useState<GridRowId[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false)
  const history = useHistory()

  const confirmDeleteContact = () => {
    setIsShowingModal(false)
    deleteContact(contactWorkplaceId, contactIdToDelete)
  }

  const editContact = (row: MyContactsSearch, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    history.push(contactDetailsEditUrl(row.workplaceId, row.contactId))
  }

  const navigateToContact: GridEventListener<'rowClick'> = (params: GridRowParams<MyContactsSearch>) => {
    history.push(contactDetailsUrl(params.row.workplaceId, params.row.contactId))
  }

  const handleShowActiveContactsChange = () => setIsShowingOnlyActiveContacts(!isShowingOnlyActiveContacts)
  const handleShowOnlyMainContacts = () => setIsShowingOnlyMainContacts(!isShowingOnlyMainContacts)

  const cancelDelete = () => {
    setIsShowingModal(false)
    setContactIdToDelete(null)
    setContactWorkplaceId(null)
  }

  const showModal = (row: MyContactsSearch, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setIsShowingModal(true)
    setContactIdToDelete(row?.contactId)
    setContactWorkplaceId(row?.workplaceId)
  }

  useEffect(() => {
    setClearSelectedRows(createEmailState === FetchingState.SUCCESS)
  }, [createEmailState])

  if (loading) {
    return <Loading />
  }

  const getSearchResults = () => {
    return myContactsData.filter((contact: { status: { statusId: string }; isMainContact: boolean }) => {
      const isActiveContactFilter = isShowingOnlyActiveContacts ? contact.status.statusId === '0' : true
      const isMainContactFilter = isShowingOnlyMainContacts ? contact.isMainContact : true
      return isActiveContactFilter && isMainContactFilter
    })
  }

  const searchResults = getSearchResults()

  const getRecipients = (): ICreateEmailRecipients[] => {
    return searchResults
      .reduce((accumulator: MyContactsSearch[], current: MyContactsSearch) => {
        selectedRowIds.some(rowId => current.contactId === rowId) && accumulator.push(current)
        return accumulator
      }, [])
      .map((value: MyContactsSearch): ICreateEmailRecipients => ({ id: value.contactId, emailAddress: value.email}))
  }

  const isAdvancedSearch = history.location.pathname.includes('advanced')
  const resultType = isAdvancedSearch ? 'Sökresultat' : 'Mina kontaktpersoner'
  const handleSetColumnVisibility = (model: GridColumnVisibilityModel) => setColumnVisibility(model as ContactColumnVisibility)

  return (
    <>
      <Typography variant='h4' marginBottom={2}>{`${resultType} (${visibleRowIds.length})`}</Typography>

      <Box display='flex' marginLeft={2} marginBottom={2}>
        <FormControlLabel
          label='Visa även inaktiva kontakter'
          control={
            <Checkbox checked={!isShowingOnlyActiveContacts} onChange={handleShowActiveContactsChange} />
          }
        />
        <Box>
          <FormControlLabel
            label='Visa endast huvudkontakter'
            control={
              <Checkbox checked={isShowingOnlyMainContacts} onChange={handleShowOnlyMainContacts} name='isMainContact' />
            }
            sx={{ marginRight: 1 }}
          />
          <InformationPopover
            infoText='Syftet med att märka upp en kontakt som en huvudkontakt är att du snabbt kan få fram de personer som
            du jobbar med mest frekvent, exempelvis vid gruppmejl.'
          />
        </Box>
      </Box>

      {searchResults?.length === SEARCH_MAX && (
        <Alert severity='warning'>
          {`Maximalt antal sökresultat (${SEARCH_MAX}) uppnått. Förfina sökningen om du vill vara
          säker på att få med alla relevanta resultat`}
        </Alert>
      )}

      <DataGridWrapper
        rows={searchResults}
        columns={contactSearchResultColumns(editContact, showModal)}
        columnVisibilityModel={columnVisibility}
        columnVisibilityModelChange={handleSetColumnVisibility}
        getRowId={(row: MyContactsSearch) => row.contactId}
        labelRowsPerPage='Kontaktpersoner per sida'
        onRowClick={navigateToContact}
        sortModel={sortModel as GridSortModel}
        setSortModel={setSortModel}
        getVisibleRowIds={setVisibleRowIds}
        showDefaultToolbar
        checkboxSelection
        getSelectedRowIds={setSelectedRowIds}
        clearSelectedRows={clearSelectedRows}
        customActions={
          <CreateEmail
            recipients={getRecipients()}
            createEmail={createEmail}
            createEmailState={createEmailState}
          />
        }
      />

      <ConfirmationModal
        active={isShowingModal}
        close={cancelDelete}
        submit={confirmDeleteContact}
        message={`Skapade händelser finns kvar när en kontaktperson tas bort. Däremot försvinner personens namn ifrån händelsen.

        Är du säker på att du vill ta bort denna kontaktperson?`}
        title='Bekräfta borttagning'
      />
    </>
  )
}

export default ContactSearchResult
