import { connect } from 'react-redux'
import { createEmail } from '@local/Blocks/CreateEmail/CreateEmail.actions'
import { RootState } from '@local/Store/configureStore'

import { deleteContact } from '../../../Tabs/Contacts/Contacts.actions'

import ContactSearchResult from './ContactSearchResult'

const mapStateToProps = (state: RootState) => ({
  loading: state.contacts.myContactsLoading,
  myContactsData: state.contacts.myContactsData,
  showOnlyActiveContacts: state.contacts.showOnlyActiveContacts,
  showOnlyMainContacts: state.contacts.showOnlyMainContacts,
  createEmailState: state.createEmail.createEmailState,
})

const mapDispatchToProps = {
  deleteContact,
  createEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearchResult)
