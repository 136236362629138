import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetCountiesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'

import { loadedFilterFromUrl, setSelectedCounties } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const CountyFilter = ({ isSearching }: IFilterProps) => {
    const { data: counties, isFetching: isLoadingCounties, isError: hasFailedToLoadCounties } = useGetCountiesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedCounties
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (counties?.length > 0) {
            const idsFromParams = getStringArrayParamValue('counties')
            dispatch(setSelectedCounties(counties.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counties])

    return (
        <Autocomplete
            options={counties ?? []}
            value={selectedCounties}
            onChange={(_, value) => dispatch(setSelectedCounties(value))}
            getOptionLabel={(c) => c.name}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Län'
                error={hasFailedToLoadCounties}
                helperText={hasFailedToLoadCounties ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingCounties || hasFailedToLoadCounties || isSearching}
        />
    )
}