import { GridColDef } from '@mui/x-data-grid'
import { Box, IconButton, Tooltip } from '@mui/material'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { MyContactsSearch } from '../Contacts.types'

export const contactSearchResultColumns = (
  editFunc: (row: MyContactsSearch, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  deleteFunc: (row: MyContactsSearch, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void): GridColDef<MyContactsSearch>[] => {
  return [
    {
      field: 'isMainContact',
      headerName: 'Huvudkontakt',
      renderCell: ({row}) => {
        return row.isMainContact ? (
          <Box width='100%' display='flex' justifyContent='center'>
            <Tooltip title='Huvudkontakt'>
              <StarIcon color='primary' />
            </Tooltip>
          </Box>
        ) : ''
      },
      flex: 1,
      maxWidth: 125,
      disableColumnMenu: true,
      filterable: false,
      hideable: false,
    },
    {
      field: 'fullName',
      headerName: 'Namn',
      renderCell: ({ row }) => tableTooltip(row?.fullName),
      flex: 1,
    },
    {
      field: 'mobile.number',
      headerName: 'Mobil',
      valueGetter: ({ row }) => row?.mobile.number,
      renderCell: ({ row }) => tableTooltip(row?.mobile.number),
      flex: 1,
      maxWidth: 100,
    },
    {
      field: 'email',
      headerName: 'E-post',
      renderCell: ({ row }) => tableTooltip(row?.email),
      flex: 1,
    },
    {
      field: 'roleLevelTwo.roleLevel2Text',
      headerName: 'Roll',
      valueGetter: ({ row }) => row?.roleLevelTwo?.roleLevel2Text,
      renderCell: ({ row }) => tableTooltip(row?.roleLevelTwo?.roleLevel2Text),
      flex: 1,
      maxWidth: 185,
    },
    {
      field: 'workplaceName',
      headerName: 'Arbetsställe',
      renderCell: ({ row }) => tableTooltip(row?.workplaceName),
      flex: 1,
    },
    {
      field: 'roleLevelOne.roleLevel1Text',
      headerName: 'Kategori',
      valueGetter: ({ row }) => row?.roleLevelOne?.roleLevel1Text,
      renderCell: ({ row }) => tableTooltip(row?.roleLevelOne?.roleLevel1Text),
      flex: 1,
      maxWidth: 85
    },
    {
      field: 'status.statusText',
      headerName: 'Status',
      valueGetter: ({ row }) => row?.status?.statusText,
      renderCell: ({ row }) => tableTooltip(row?.status.statusText),
      flex: 1,
      maxWidth: 175,
    },
    {
      field: 'updatedBy',
      headerName: 'Senast uppdaterad',
      valueGetter: ({ row }) => row.updatedBy.timestamp ?? row.createdBy.timestamp,
      renderCell: ({ row }) => tableTooltip((row.updatedBy.timestamp ?? row.createdBy.timestamp).substring(0, 10)),
      flex: 1,
    },
    {
      field: 'Redigera',
      headerName: '',
      renderCell: ({row}) => {
        return (
          <>
            <Tooltip title='Redigera'>
              <IconButton onClick={(event) => editFunc(row, event)} aria-label='edit contact'>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Ta bort'>
              <IconButton onClick={(event) => deleteFunc(row, event)} aria-label='deleteContact'>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      },
      flex: 1,
      maxWidth: 100,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
    },
  ]
}