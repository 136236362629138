export type QueryStringParamValue = string | string[] | number | boolean
export type QueryStringParams<TValue = QueryStringParamValue> = Record<string, TValue | undefined>

export const VALUE_SEPARATOR = ';'
export const ESCAPED_VALUE_SEPARATOR =
  '%' +
  VALUE_SEPARATOR.charCodeAt(0)
    .toString(16)
    .toUpperCase()

export const buildQueryString = (params: unknown): string => {
  return stringify(params as QueryStringParams)
}

/**
 * Used for parsing object into a query string.
 * It is capable of retrieving value from ISelectOption type.
 * @param obj - any object containing strings, numbers, objects of type ISelectOption or ISelectOption[]
 * @param parentKey - recursive object key, not used when calling function first time
 * @returns query string representation of the object passed in
 */
export const objectToQueryParams = (obj: any, parentKey?: string): string => {
  const queryParams = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      const encodedKey = parentKey ? `${parentKey}.${key}` : key

      if (Array.isArray(value)) {
        const encodedValues = value.map(item => {
          if (typeof item === 'number' || typeof item === 'string') {
            return encodeURIComponent(item)
          }
          return encodeURIComponent(item.value as string)
        })
        queryParams.push(`${encodedKey}=${encodedValues.join(ESCAPED_VALUE_SEPARATOR)}`)
      } else if (value && typeof value === 'object') {
        if (Object.prototype.hasOwnProperty.call(value, 'value')) {
          const encodedValue = encodeURIComponent(value.value as string)
          queryParams.push(`${encodedKey}=${encodedValue}`)
        }
        else {
          queryParams.push(objectToQueryParams(value, encodedKey))
        }
      } else {
        const encodedValue = encodeURIComponent(value as string)
        queryParams.push(`${encodedKey}=${encodedValue}`)
      }
    }
  }

  return queryParams.length > 0 ? `?${queryParams.join('&')}`: ''
}

export const stringify = (params: QueryStringParams) => {
  const joined = Object.entries(params)
    // Only stringify params with values.
    .filter(
      ([, value]) =>
        value !== null &&
        value !== undefined &&
        (typeof value === 'number' || typeof value === 'boolean' || (value.length && value[0] !== undefined))
    )
    .map(([name, paramValue]) => {
      paramValue = typeof paramValue === 'number' ? encodeURIComponent(String(paramValue)) : paramValue
      const values: string[] = [].concat(paramValue)
      values.forEach(value => {
        if (value.toString().includes(VALUE_SEPARATOR)) {
          throw new Error(`A value may not contain a semicolon: '${value}'`)
        }
      })
      return [name, values.join(VALUE_SEPARATOR)]
    })

  const qs = new URLSearchParams(joined)
  const qsString = qs.toString()
  return qsString ? `?${qsString}` : ''
}

export interface ParseOptions<B = boolean> {
  forceArray?: B
}

export const parse = <B extends boolean>(
  query: string,
  { forceArray }: ParseOptions<B> = {}
): B extends true ? QueryStringParams<string[]> : QueryStringParams | null => {
  const entries = Array.from(new URLSearchParams(query).entries()).filter(([, value]) => value)
  const qs = entries.reduce((obj, [key, values]) => {
    const decoded = values.split(VALUE_SEPARATOR)
    if (decoded.length === 1 && !forceArray) {
      obj[key] = decoded[0]
    } else {
      obj[key] = decoded
    }
    return obj
  }, {}) as B extends true ? QueryStringParams<string[]> : QueryStringParams

  return qs
}
