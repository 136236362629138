import { ICountry } from '@local/src/Models/Domain/Country.model'
import { ICounty } from '@local/src/Models/Domain/County.model'
import { IMunicipality } from '@local/src/Models/Domain/Municipality.model'
import { ITrrIndustry } from '@local/src/Models/Domain/TrrIndustry.model'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'
import {
  CompanyType,
  County,
  CompanyStatus,
  EligibilityStatus,
  Municipality,
  PostalAddress,
  WhiteCollarBisnode,
  WhiteCollarTrr,
  VisitingAddress,
  Country,
} from '@local/src/Scenes/Company/Company.model'

export interface IWorkplaceSearchResponseApiModel {
  address: string
  companyGroupParentOrganizationalNumber: string
  isPartOfCompanyGroup: boolean
  companyType: CompanyType
  county: County
  localCustomerManager: IWorkplaceSearchEmployeeResponseApiModel
  nationalCustomerManager: IWorkplaceSearchEmployeeResponseApiModel
  localContact: IWorkplaceSearchEmployeeResponseApiModel
  email: string
  fullName: string
  homepage: string
  companyStatus: CompanyStatus
  eligibilityStatus: EligibilityStatus
  latestContact: string
  locallyImportant: boolean
  mainName: string
  municipality: Municipality
  omMagasin: string
  organizationalNumber: string
  organizationalNumberType: string
  payroll: number
  phoneCountryCode: string
  phoneNumber: string
  postalAddress: PostalAddress
  unitName: string
  whiteCollarBisnode: WhiteCollarBisnode
  whiteCollarTrr: WhiteCollarTrr
  visitingAddress: VisitingAddress
  workplaceId: string
  workplaceNumber: string
  trrIndustries: ITrrIndustry[]
}

export interface IWorkplaceSearchEmployeeResponseApiModel {
  employeeId: string
  employeeName: string
}

export const mapToWorkplace = (data: IWorkplaceSearchResponseApiModel): IWorkplace => ({
  address: data.address,
  companyGroupParentOrganizationalNumber: data.companyGroupParentOrganizationalNumber,
  isPartOfCompanyGroup: data.isPartOfCompanyGroup,
  companyType: { id: data.companyType?.companyTypeId, name: data.companyType?.name },
  county: mapCounty(data.county),
  localCustomerManager: {
    id: data.localCustomerManager?.employeeId,
    fullName: data.localCustomerManager?.employeeName,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
  },
  nationalCustomerManager: {
    id: data.nationalCustomerManager?.employeeId,
    fullName: data.nationalCustomerManager?.employeeName,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
  },
  localContact: {
    id: data.localContact?.employeeId,
    fullName: data.localContact?.employeeName,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
  },
  email: data.email,
  fullName: data.fullName,
  homepage: data.homepage,
  companyStatus: {
    id: data.companyStatus?.companyStatusCode.toString(),
    description: data.companyStatus.companyStatusDescription,
  },
  eligibilityStatus: {
    id: data.eligibilityStatus?.eligibilityStatusCode,
    description: data.eligibilityStatus?.eligibilityStatusDescription,
  },
  latestContact: data.latestContact,
  locallyImportant: data.locallyImportant,
  mainName: data.mainName,
  municipality: mapMunicipality(data.municipality),
  omMagasin: data.omMagasin,
  organizationalNumber: data.organizationalNumber,
  organizationalNumberType: data.organizationalNumberType,
  payroll: data.payroll,
  phoneCountryCode: data.phoneCountryCode,
  phoneNumber: data.phoneNumber,
  postalAddress: {
    coAddress: data.postalAddress?.coAddress,
    street: data.postalAddress?.street,
    town: data.postalAddress?.town,
    zipCode: data.postalAddress?.zipCode,
    municipality: mapMunicipality(data.postalAddress?.municipality),
    county: mapCounty(data.postalAddress?.county),
    country: mapCountry(data.postalAddress?.country),
  },
  unitName: data.unitName,
  whiteCollarBisnode: {
    id: data.whiteCollarBisnode?.whitecollarBisnodeCode,
    description: data.whiteCollarBisnode?.whitecollarBisnodeDescription,
    date: data.whiteCollarBisnode?.whitecollarBisnodeDate,
  },
  whiteCollarTrr: {
    count: data.whiteCollarTrr?.trrNumber,
    date: data.whiteCollarTrr?.trrUpdateDate,
  },
  visitingAddress: {
    street: data.visitingAddress?.street,
    town: data.visitingAddress?.town,
    municipality: mapMunicipality(data.visitingAddress?.municipality),
    county: mapCounty(data.visitingAddress?.county),
    country: mapCountry(data.visitingAddress?.country),
  },
  workplaceId: data.workplaceId,
  workplaceNumber: data.workplaceNumber,
  trrIndustries: data.trrIndustries,
})

const mapMunicipality = (municipality: Municipality): IMunicipality => ({
  id: municipality?.municipalityCode,
  name: municipality?.municipalityName,
  countyId: municipality?.countyId,
})

const mapCounty = (county: County): ICounty => ({
  id: county?.countyCode,
  name: county?.countyName,
})

const mapCountry = (country: Country): ICountry => ({
  id: country?.countryCode,
  name: country?.countryName,
})
