import React from 'react'
import { companyOverviewUrl } from '@local/src/basename'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'
import { formatCompanyTypeName, prettyPrintAmount } from '@local/src/Utils/helpers'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { GridColDef } from '@mui/x-data-grid'
import { Link } from '@mui/material'


export const workplaceSearchResultColumns = (): GridColDef<IWorkplace>[] => [
  {
    headerName: 'Org.nr',
    field: 'organizationalNumber',
    valueGetter: ({ row }) => row?.organizationalNumber,
    renderCell: ({ formattedValue, row }) => (<Link data-testid="organisational-number-link" href={companyOverviewUrl(row.workplaceId)}>{formattedValue}</Link>),
    width: 105,
  },
  {
    headerName: 'Namn',
    field: 'fullName',
    valueGetter: ({ row }) => row?.fullName,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
    minWidth: 160,
  },
  {
    headerName: 'Län',
    field: 'County',
    valueGetter: ({ row }) => row?.county?.name,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Kommun',
    field: 'municipality',
    valueGetter: ({ row }) => row?.municipality?.name,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'HK/AP',
    field: 'companyType',
    valueGetter: ({ row }) => row?.companyType?.name,
    valueFormatter: ({ value }) => formatCompanyTypeName(value as string),
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    width: 70,
    sortingOrder: ['desc', 'asc']
  },
  {
    headerName: 'Nationellt ansvarig',
    field: 'nationalAccountManager',
    valueGetter: ({ row }) => row?.nationalCustomerManager?.fullName,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Lokalt ansvarig',
    field: 'localAccountManager',
    valueGetter: ({ row }) => row?.localCustomerManager?.fullName,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,

  },
  {
    headerName: 'Lokal kontakt',
    field: 'localContact',
    valueGetter: ({ row }) => row?.localContact?.fullName,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Kund',
    field: 'eligibilityStatus',
    valueGetter: ({ row }) => row?.eligibilityStatus?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
    maxWidth: 60,
  },
  {
    headerName: 'Företagsstatus',
    field: 'companyStatus',
    valueGetter: ({ row }) => row?.companyStatus?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Huvudbransch',
    field: 'trrIndustry',
    valueGetter: ({ row }) => row?.trrIndustries?.find((ti) => ti.isMain)?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'D&B (Antal tjänstemän)',
    field: 'whiteCollarBisnodeCode',
    valueGetter: ({ row }) => row?.whiteCollarBisnode?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'TRR (Antal tjänstemän)',
    field: 'whiteCollarTrr',
    valueGetter: ({ row }) => row?.whiteCollarTrr?.count,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Lönesumma, MSEK',
    field: 'payroll',
    valueGetter: ({ row }) => row?.payroll,
    valueFormatter: ({ value }) => {
      const millionSek = value / 1000000
      if (millionSek === 0) return '0'
      if (millionSek < 1) return '<1'

      return prettyPrintAmount(millionSek)
    },
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
  },
  {
    headerName: 'Senaste händelse',
    field: 'latestContact',
    valueGetter: ({ row }) => row?.latestContact,
    valueFormatter: ({ value }) => value?.replace('0001-01-01T00:00:00', '').substring(0, 10) || '',
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    width: 95,
  },
]

export const COLUMN_NAMES = workplaceSearchResultColumns().map(c => c.field)