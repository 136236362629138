import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useGetCompanyStatusesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { loadedFilterFromUrl, setSelectedWorkplaceStatuses } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const WorkplaceStatusFilter = ({ isSearching }: IFilterProps) => {
    const { data: workplaceStatuses, isFetching: isLoadingWorkplaceStatuses, isError: hasFailedToLoadWorkplaceStatuses } = useGetCompanyStatusesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedWorkplaceStatuses
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (workplaceStatuses?.length > 0) {
            const idsFromParams = getStringArrayParamValue('workplaceStatuses')
            dispatch(setSelectedWorkplaceStatuses(workplaceStatuses.filter(x => idsFromParams.includes(x.id.toString()))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workplaceStatuses])

    return (
        <Autocomplete
            options={workplaceStatuses ?? []}
            value={selectedWorkplaceStatuses}
            onChange={(_, value) => dispatch(setSelectedWorkplaceStatuses(value))}
            getOptionLabel={(ws) => ws.description}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Företagsstatus'
                error={hasFailedToLoadWorkplaceStatuses}
                helperText={hasFailedToLoadWorkplaceStatuses ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingWorkplaceStatuses || hasFailedToLoadWorkplaceStatuses || isSearching}
        />
    )
}