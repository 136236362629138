import React, { useEffect } from 'react'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { useLazyGetCompanyDetailsQuery, useLazyGetCompanyGroupQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { companyGroupColumns } from './CompanyGroup.columns'

export interface IGroupWorkplace {
  id: string
  namn: string
  organisationsnummer: string
  nationelltKundansvarig: string
  lokaltKundansvarig: string
  isModerbolag: boolean
  isArbetsstalle: boolean
  isAnslutet: boolean
  senasteAktivitet: IActivity
}

export interface IActivity {
  id: string
  aktivitetTypNamn: string
  datum: string
  arbetsstalleId: string
}

export const CompanyGroup = () => {
  const { companyGuid } = useParams<{ companyGuid: string }>()

  const [fetchCompany, { data: company, isLoading: isLoadingCompany, isError: failedToFetchCompany }] = useLazyGetCompanyDetailsQuery()
  const [fetchCompanyGroup, { data: companyGroup, isLoading: isLoadingCompanyGroup, isError: failedToFetchCompanyGroup }] = useLazyGetCompanyGroupQuery()

  useEffect(() => {
    void fetchCompany(companyGuid)
  }, [companyGuid])

  useEffect(() => {
    if (company)
      void fetchCompanyGroup(company.companyGroupParentOrganizationalNumber)
  }, [company])


  if (isLoadingCompany || isLoadingCompanyGroup || !company || !companyGroup)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size="1rem" data-testid="loading-company-group" />
      </Box>
    )

  if (failedToFetchCompany || failedToFetchCompanyGroup)
    return <Alert severity="error">Kunde inte hämta informationen, försök ladda om sidan.</Alert>

  if (!company.isPartOfCompanyGroup || (!isLoadingCompany && !isLoadingCompanyGroup && !companyGroup.length))
    return <Typography variant="body1">Det finns inga huvudkontor i koncernen som är anslutna till TRR.</Typography>

  return (
    <>
      <Typography variant="body1" paddingBottom={3}>
        {`${company.fullName} ingår i en koncern. Här visas de huvudkontor i samma koncern som är anslutna till TRR.`}
      </Typography>
      <Typography variant="subtitle1" paddingY={1}>
        {companyGroup.length} huvudkontor
      </Typography>

      <DataGridWrapper
        rows={companyGroup}
        columns={companyGroupColumns(companyGuid)}
        getRowId={(row: IGroupWorkplace) => row.id ?? `${row.organisationsnummer}${row.namn}`}
      />
    </>
  )
}
