import React, { useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { differenceInMinutes, format } from 'date-fns'
import { useAppShellData } from '@local/src/AppShellData'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { activitiesUrl, activityDetailsUrl } from '@local/src/basename'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ActivityCategories, ActivitySeminarCodes, ICompanyManager, ISelectOption, SubjectMatters } from '@local/src/App.types'
import { RootState, useAppDispatch } from '@local/Store/configureStore'
import {
  createActivity,
  deleteActivity,
  fetchDetailedActivityData,
  resetActivity,
  updateActivity,
} from '@local/Scenes/Company/CompanyTabs/Activities/Activities.actions'
import { CompanyStore } from '@local/Scenes/Company/Company.reducers'
import { formControlHasError } from '@local/src/Utils/helpers'
import { fetchContactsData } from '@local/Scenes/Company/Company.actions'
import { useSelector } from 'react-redux'
import Loading from '@local/src/Components/Loading/Loading'
import { array, string, number, object } from 'yup'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useGetCompanyManagersQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { useGetActivityCategoriesQuery, useGetActivitySeminarCodesQuery, useGetActivityStatusesQuery, useGetActivitySubjectMattersQuery } from '@local/src/Utils/network/endpoints/activitiesApi'
import { useUser } from '@trr/app-shell-data'

import { ActivityModel, Colleague, Contact, EmployeeResponsible } from '../Activities.model'

import { durations } from './HandleActivity.values'
import { getActivityCreateModel, getActivityUpdateModel } from './HandleActivity.helpers'
import DeleteActivityModal from './DeleteActivityModal/DeleteActivityModal'

export interface IActivityFormValues {
  date: string
  attendeesNumber: number
  category: string
  customerSpecificSeminar: string
  duration: number
  location?: string
  processType?: string
  sendInviteToContact: boolean
  startTime?: string
  status: string
  subject?: string
  subjectMatter: string
  text?: string
  selectedColleagues: ISelectOption[]
  selectedContacts: ISelectOption[]
  selectedEmployeesResponsible: ISelectOption[]
}

export const getValidationError = (formikProps: FormikProps<IActivityFormValues>, formProp: string, create: boolean) =>
  (formikProps.errors && formikProps.touched[formProp] && formikProps.errors[formProp]) ||
  (!create && formikProps.errors && formikProps.errors[formProp])

export const getDuration = (initialValues: IActivityFormValues, formikProps: FormikProps<IActivityFormValues>) => {
  return durations.find((x: { value: number }) => x.value === formikProps.values.duration) ?? initialValues.duration
}

const getInitialValues = (
  initialValues: IActivityFormValues,
  create: boolean,
  userEmail: string,
  activity: ActivityModel,
  processTypeCode: string,
  companyManagers: ICompanyManager[]
) => {
  if (create) {
    initialValues = {
      ...initialValues,
      date: format(new Date(), 'yyyy-MM-dd'),
      selectedEmployeesResponsible: companyManagers
        .filter(cm => cm.email === userEmail)
        .map(user => ({ value: user.value, label: user.label })),
      processType: processTypeCode,
      startTime: format(new Date(), 'HH:mm'),
      attendeesNumber: undefined,
      subject: undefined,
    }
  } else {
    initialValues = {
      ...initialValues,
      attendeesNumber: activity.attendeesNumber,
      processType: processTypeCode,
      category: activity.category.categoryCode,
      customerSpecificSeminar: activity.customerSpecificSeminar.customerSpecificSeminarCode,
      subject: activity.subject ?? '',
      date: format(new Date(activity.range.start), 'yyyy-MM-dd'),
      location: activity.location,
      startTime: format(new Date(activity.range.start), 'HH:mm'),
      duration: differenceInMinutes(new Date(activity.range.end), new Date(activity.range.start)),
      text: activity.text ?? '',
      sendInviteToContact: activity.sendInviteToContact,
      subjectMatter: activity.subjectMatter.subjectMatterCode,
      status: activity.status.statusCode,
      selectedEmployeesResponsible: activity.employeesResponsible.map((er: EmployeeResponsible) => ({
        value: er.employeeResponsibleId,
        label: er.employeeName,
      })),
      selectedColleagues: activity.colleagues.map((c: Colleague) => ({
        value: c.colleagueId,
        label: c.colleagueName,
      })),
      selectedContacts: activity.contacts.map((c: Contact) => ({
        value: c.contactId,
        label: c.contactName,
      })),
    }
  }
  return initialValues
}

export interface IHandleActivityProps {
  activity: ActivityModel
  company?: CompanyStore
  contactsLoaded?: boolean
  create?: boolean
  createOrUpdateFailed?: boolean
  errorMessage?: string
  saveInProgress?: boolean
}

const HandleActivity = ({
  activity,
  company,
  contactsLoaded,
  createOrUpdateFailed,
  errorMessage,
  saveInProgress,
}: IHandleActivityProps) => {
  const { email: userEmail } = useUser()
  const history = useHistory()
  const { companyGuid, activityGuid } = useParams<{ companyGuid: string; activityGuid: string }>()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const query = new URLSearchParams(location.search as URLSearchParams)
  const create = location.pathname.includes('skapa') as boolean
  const processTypeCode = create ? query.get('processTypeCode') : activity?.processType?.processTypeCode
  const processTypeDescription = create
    ? query.get('processTypeDescription')
    : activity?.processType?.processTypeDescription
  const updateInProgress = useSelector((state: RootState) => state.activity.updateActivityInProgress)
  const createInProgress = useSelector((state: RootState) => state.activity.createActivityInProgress)
  const deleteInProgress = useSelector((state: RootState) => state.activity.deleteInProgress)

  const { data: companyManagersFromQuery, isLoading: isLoadingCompanyManagers } = useGetCompanyManagersQuery()
  const { data: activityCategories, isLoading: isLoadingActivityCategories } = useGetActivityCategoriesQuery()
  const { data: activitySeminarCodes, isLoading: isLoadingActivitySeminarCodes } = useGetActivitySeminarCodesQuery()
  const { data: activityStatuses, isLoading: isLoadingActivityStatuses } = useGetActivityStatusesQuery()
  const { data: activitySubjectMatters, isLoading: isLoadingActivitySubjectMatters } = useGetActivitySubjectMattersQuery()

  const isDataLoaded = !isLoadingCompanyManagers && !isLoadingActivityCategories && !isLoadingActivitySeminarCodes && !isLoadingActivityStatuses && !isLoadingActivitySubjectMatters

  useEffect(() => {
    dispatch(fetchContactsData(companyGuid))
    if (!create) {
      dispatch(fetchDetailedActivityData(activityGuid))
    }

    return () => {
      dispatch(resetActivity())
    }
  }, [activityGuid, companyGuid, create, dispatch])

  const prevDeleteInProgress = useRef(deleteInProgress)

  useEffect(() => {
    const finishedDeleting = prevDeleteInProgress.current && !deleteInProgress
    if (finishedDeleting) {
      history.push(activitiesUrl(companyGuid))
    }
    prevDeleteInProgress.current = deleteInProgress
  }, [activityGuid, companyGuid, createOrUpdateFailed, history, deleteInProgress])

  const prevUpdateInProgress = useRef(updateInProgress)

  useEffect(() => {
    const finishedUpdating = prevUpdateInProgress.current && !updateInProgress && !createOrUpdateFailed
    if (finishedUpdating) {
      history.push(activityDetailsUrl(companyGuid, activityGuid))
    }
    prevUpdateInProgress.current = updateInProgress
  }, [activityGuid, companyGuid, createOrUpdateFailed, history, updateInProgress])

  const prevCreateInProgress = useRef(createInProgress)

  useEffect(() => {
    const finishedCreating = prevCreateInProgress.current && !createInProgress && !createOrUpdateFailed
    if (finishedCreating) {
      history.push(activitiesUrl(companyGuid))
    }
    prevCreateInProgress.current = createInProgress
  }, [activityGuid, companyGuid, history, createInProgress, createOrUpdateFailed])

  if ((!create && !activity) || !contactsLoaded || !isDataLoaded) {
    return <Loading />
  }

  const companyManagers = companyManagersFromQuery.map(cm => ({ value: cm.id, label: cm.fullName, email: cm.email }) as ICompanyManager)

  let initialValues: IActivityFormValues = {
    date: format(new Date(), 'yyyy-MM-dd'),
    category: '',
    customerSpecificSeminar: '',
    duration: 30,
    sendInviteToContact: false,
    status: '',
    subjectMatter: '',
    selectedColleagues: [],
    selectedContacts: [],
    selectedEmployeesResponsible: [],
    attendeesNumber: undefined,
    subject: undefined,
  }

  initialValues = getInitialValues(initialValues, create, userEmail, activity, processTypeCode, companyManagers)

  const isAnteckning = processTypeCode === 'Z001'
  const isKontakt = processTypeCode === 'Z002'
  const isMotesboking = processTypeCode === 'Z003'
  const isInsatsIOmstallningssituation = processTypeCode === 'Z004'
  const activeContacts = company.contacts.filter(r => r.status.statusId === '0')
  const activeSeminarCodes = activitySeminarCodes.filter(
    code => code.value === initialValues.customerSpecificSeminar || code.isActive
  )

  const onSubmit = (values: IActivityFormValues) => {
    if (create) {
      const activityToCreate = getActivityCreateModel(activityGuid, companyGuid, values)
      dispatch(createActivity(activityToCreate))
    } else {
      const activityToUpdate = getActivityUpdateModel(activity, companyGuid, values)
      dispatch(updateActivity(activityToUpdate))
    }
  }

  const handleCancel = () => {
    history.push(activitiesUrl(companyGuid))
  }

  const handleDeleteActivity = () => {
    setModalIsOpen(false)
    dispatch(deleteActivity(companyGuid, activityGuid))
  }

  return (
    <Stack>
      <Typography variant="h4" marginBottom={3}>
        {create ? 'Skapa ny' : 'Redigera'} {processTypeDescription.toLowerCase()}
      </Typography>

      {createOrUpdateFailed && <Alert severity="error">Fel vid skapande/uppdatering av händelse: {errorMessage}</Alert>}
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount>
        {(formikProps: FormikProps<IActivityFormValues>) => (
          <Stack component='form' onSubmit={formikProps.handleSubmit}>
            {saveInProgress ? (
              <Loading />
            ) : (
              <Stack>
                <Box display="flex" flexDirection="column" rowGap={3} marginBottom={3}>
                  <Dropdown
                    name='subjectMatter'
                    defaultValue={
                      activitySubjectMatters.find(x => x.value === formikProps.values.subjectMatter)?.value
                    }
                    value={formikProps.values.subjectMatter}
                    options={activitySubjectMatters}
                    getOptionLabel={(value: SubjectMatters) => value.label}
                    getOptionValue={(value: SubjectMatters) => value.value}
                    label='Välj ämnesområde'
                    onChange={event => void formikProps.setFieldValue('subjectMatter', event.target.value)}
                    error={formControlHasError(formikProps, 'subjectMatter')}
                    errorText={formikProps.errors?.subjectMatter}
                    sx={{ width: '368px' }}
                  />

                  {isMotesboking && (
                    <TextField
                      sx={{ width: '368px' }}
                      label="Ämnesrad"
                      name="subject"
                      helperText={formControlHasError(formikProps, 'subject') && formikProps.errors?.subject}
                      error={formControlHasError(formikProps, 'subject')}
                      defaultValue={formikProps.values.subject}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                    />
                  )}

                  <DatePicker
                    sx={{ width: '368px' }}
                    label="Datum"
                    value={new Date(formikProps.values.date)}
                    onChange={date => {
                      void formikProps.setFieldValue('date', format(date, 'yyyy-MM-dd'))
                    }}
                  />

                  {isKontakt && (
                    <Dropdown
                      label='Välj typ av kontakt'
                      name="category"
                      sx={{ width: '368px' }}
                      error={formControlHasError(formikProps, 'category')}
                      errorText={formControlHasError(formikProps, 'category') && formikProps.errors?.category}
                      options={activityCategories.filter(category => category.processType === processTypeCode)}
                      getOptionLabel={(value: ActivityCategories) => value.label}
                      getOptionValue={(value: ActivityCategories) => value.value}
                      onChange={event => void formikProps.setFieldValue('category', event.target.value)}
                      value={formikProps.values.category}
                    />
                  )}

                  {isInsatsIOmstallningssituation && (
                    <Box display="flex" flexDirection="column" rowGap={3}>
                      <TextField
                        sx={{ width: '368px' }}
                        label="Plats för mötet"
                        name="location"
                        helperText={getValidationError(formikProps, 'location', create) || 'Frivilligt'}
                        error={formControlHasError(formikProps, 'location')}
                        defaultValue={formikProps.values.location}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />

                      <TextField
                        sx={{ width: '368px' }}
                        label="Antal deltagare"
                        id="attendeesNumber"
                        name="attendeesNumber"
                        helperText={formControlHasError(formikProps, 'attendeesNumber') && formikProps.errors?.attendeesNumber}
                        error={formControlHasError(formikProps, 'attendeesNumber')}
                        value={formikProps.values.attendeesNumber}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                      <Dropdown
                        label='Välj händelsetyp'
                        value={formikProps.values.customerSpecificSeminar}
                        options={activeSeminarCodes}
                        getOptionLabel={((value: ActivitySeminarCodes) => value.label)}
                        getOptionValue={((value: ActivitySeminarCodes) => value.value)}
                        defaultValue={
                          activitySeminarCodes.find(
                            x => x.value === formikProps.values.customerSpecificSeminar
                          )?.value
                        }
                        onChange={event => void formikProps.setFieldValue('customerSpecificSeminar', event.target.value)}
                        error={formControlHasError(formikProps, 'customerSpecificSeminar')}
                        errorText={formControlHasError(formikProps, 'customerSpecificSeminar') && formikProps.errors?.customerSpecificSeminar}
                        sx={{ width: '368px' }}
                      />
                    </Box>
                  )}

                  {isMotesboking && (
                    <Box display="flex" flexDirection="column" rowGap={3}>
                      <Box display="flex" flexDirection="row" gap="16px">
                        <TextField
                          sx={{ width: '215px' }}
                          label="Starttid"
                          name="startTime"
                          helperText={getValidationError(formikProps, 'startTime', create)}
                          error={formControlHasError(formikProps, 'startTime')}
                          value={formikProps.values.startTime}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                        <FormControl>
                          <InputLabel>Längd</InputLabel>
                          <Select
                            sx={{ width: '137px' }}
                            data-testid="duration-select"
                            name="duration"
                            error={formControlHasError(formikProps, 'duration')}
                            defaultValue={getDuration(initialValues, formikProps)}
                            value={formikProps.values.duration}
                            label="Längd"
                            onChange={event => void formikProps.setFieldValue('duration', event.target.value)}
                          >
                            {durations.map((duration, index: number) => (
                              <MenuItem key={index} value={duration.value}>
                                {duration.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <FormControl>
                        <InputLabel>Välj status på mötet</InputLabel>
                        <Select
                          sx={{ width: '368px' }}
                          data-testid="status-select"
                          name="status"
                          error={formControlHasError(formikProps, 'status')}
                          defaultValue={
                            activityStatuses
                              .filter(status => status.processTypeCode === processTypeCode)
                              .find(x => x.value === formikProps.values.status)?.value
                          }
                          value={formikProps.values.status}
                          label="Välj status på mötet"
                          onChange={event => void formikProps.setFieldValue('status', event.target.value)}
                        >
                          {activityStatuses
                            .filter(status => status.processTypeCode === processTypeCode)
                            .map((activityStatus, index: number) => (
                              <MenuItem key={index} value={activityStatus.value}>
                                {activityStatus.label}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Frivilligt</FormHelperText>
                      </FormControl>

                      <TextField
                        sx={{ width: '368px' }}
                        label="Plats för mötet"
                        name="location"
                        helperText={getValidationError(formikProps, 'location', create) || 'Frivilligt'}
                        error={formControlHasError(formikProps, 'location')}
                        defaultValue={formikProps.values.location}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    </Box>
                  )}
                </Box>

                {isMotesboking && (
                  <Typography variant="h6" marginBottom={2}>
                    Ansvariga på TRR
                  </Typography>
                )}
                <Box display="flex" flexDirection="column" rowGap={3} marginBottom={3}>
                  <Autocomplete
                    sx={{ width: '368px' }}
                    multiple
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    options={companyManagers.map((cm: ICompanyManager) => ({
                      value: cm.value,
                      label: cm.label,
                    }))}
                    value={formikProps.values.selectedEmployeesResponsible}
                    onChange={(_, options) => {
                      void formikProps.setFieldTouched('selectedEmployeesResponsible')
                      void formikProps.setFieldValue(
                        'selectedEmployeesResponsible',
                        options.map((option: ISelectOption) => option)
                      )
                    }}
                    getOptionLabel={(option: ISelectOption) => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Välj ansvariga på TRR"
                        error={formControlHasError(formikProps, 'selectedEmployeesResponsible')}
                        helperText={getValidationError(formikProps, 'selectedEmployeesResponsible', create)}
                      />
                    )}
                  />

                  {isMotesboking && (
                    <Autocomplete
                      sx={{ width: '368px' }}
                      multiple
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      filterSelectedOptions
                      options={companyManagers.map((cm: ICompanyManager) => ({
                        value: cm.value,
                        label: cm.label,
                      }))}
                      value={formikProps.values.selectedColleagues}
                      onChange={(_, options) => {
                        void formikProps.setFieldTouched('selectedColleagues')
                        void formikProps.setFieldValue(
                          'selectedColleagues',
                          options.map((option: ISelectOption) => option)
                        )
                      }}
                      getOptionLabel={(option: ISelectOption) => option.label}
                      renderInput={params => (
                        <TextField {...params} label="Välj kollega på TRR" helperText="Frivilligt" />
                      )}
                    />
                  )}
                </Box>

                <Typography variant="h6" marginBottom={2}>
                  Kontaktperson på arbetsstället
                </Typography>
                <Box display="flex" flexDirection="column" rowGap={3} marginBottom={3}>
                  <Autocomplete
                    sx={{ width: '368px' }}
                    multiple
                    data-testid="contact-autocomplete"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    options={activeContacts.map(contact => ({
                      value: contact.contactId,
                      label: contact.fullName,
                    }))}
                    value={formikProps.values.selectedContacts}
                    onChange={(_, options) => {
                      if (options === null && typeof options === 'object') {
                        options = []
                      }
                      void formikProps.setFieldTouched('selectedContacts')
                      void formikProps.setFieldValue(
                        'selectedContacts',
                        options.map((option: ISelectOption) => option)
                      )
                    }}
                    getOptionLabel={(option: ISelectOption) => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Välj kontaktperson"
                        error={formControlHasError(formikProps, 'selectedContacts')}
                        helperText={
                          isAnteckning
                            ? getValidationError(formikProps, 'selectedContacts', create) || 'Frivilligt'
                            : getValidationError(formikProps, 'selectedContacts', create)
                        }
                      />
                    )}
                  />

                  {isMotesboking && (
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        label="Skicka mötesinbjudan till kontaktperson"
                        control={
                          <Checkbox
                            checked={formikProps.values.sendInviteToContact}
                            name="sendInviteToContact"
                            onChange={() =>
                              void formikProps.setFieldValue('sendInviteToContact', !formikProps.values.sendInviteToContact)
                            }
                          />
                        }
                      />
                    </Box>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" rowGap={3} marginBottom={3}>
                  <TextField
                    multiline
                    id="text"
                    label={isMotesboking ? 'Text till kunden i mötesinbjudan' : 'Notering'}
                    name="text"
                    defaultValue={formikProps.values.text}
                    onChange={formikProps.handleChange}
                    minRows={4}
                    sx={{ width: '465px' }}
                  />
                </Box>

                <Box display="flex" justifyContent="flex-start" gap={2} sx={{ width: '700px' }}>
                  <Button size="large" type='submit' disabled={deleteInProgress || createInProgress}>
                    Spara
                  </Button>
                  {!create && (
                    <Button
                      variant='outlined'
                      onClick={() => setModalIsOpen(true)}
                      disabled={deleteInProgress || createInProgress}
                    >
                      {deleteInProgress ? (
                        <CircularProgress size='1rem' />
                      ) : (
                        'Ta bort'
                      )}
                    </Button>
                  )}
                  <Button size="large" onClick={handleCancel} variant="outlined">
                    Avbryt
                  </Button>
                </Box>

                <DeleteActivityModal
                  open={modalIsOpen}
                  onCancel={() => setModalIsOpen(false)}
                  onSubmit={handleDeleteActivity}
                />
              </Stack>
            )}
          </Stack>
        )}
      </Formik>
    </Stack>
  )
}
const validationSchema = object({
  subjectMatter: string().required('Ämnesområde måste anges'),
  attendeesNumber: string()
    .when('processType', {
      is: 'Z004',
      then: () => string()
        .required('Antal deltagare måste anges')
        .min(1, 'Händelser måste ha minst en deltagare')
        .max(4, 'Vill du verkligen ange fler än 9999 deltagare?'),
    })
    .matches(/^[0-9]*$/, 'Antal deltagare måste vara ett heltal'),
  category: string().when('processType', {
    is: 'Z002',
    then: () => string().required('Typ av kontakt måste anges'),
  }),
  customerSpecificSeminar: string().when('processType', {
    is: 'Z004',
    then: () => string().required('Typ av händelse måste anges'),
  }),
  date: string()
    .typeError('Datum måste vara ett giltigt datum')
    .required('Datum måste anges')
    .matches(/^(20\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/, 'Datum måste anges på formen ÅÅÅÅ-MM-DD'),
  duration: number().when('processType', {
    is: 'Z003',
    then: () => number().required('Längd måste anges'),
  }),
  location: string()
    .nullable()
    .max(100, 'Platsbeskrivningen är begränsad till 100 tecken'),
  startTime: string().when('processType', {
    is: 'Z003',
    then: () => string()
      .required('Starttid måste anges')
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/, 'Starttid måste anges på formen hh:mm'),
  }),
  selectedEmployeesResponsible: array().min(1, 'Minst en ansvarig på TRR måste anges'),
  processType: string(),
  selectedContacts: array().when('processType', {
    is: (value) => value !== 'Z001',
    then: () => array().min(1, 'Minst en kontaktperson på arbetsstället måste anges'),
  }),
  subject: string().when('processType', {
    is: 'Z003',
    then: () => string()
      .required('Ämne måste anges')
      .max(40, 'Ämnesraden är begränsad till 40 tecken'),
  }),
})

export default HandleActivity
