import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useGetCompanyManagersQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { loadedFilterFromUrl, setSelectedLocalContacts } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const LocalContactFilter = ({ isSearching }: IFilterProps) => {
    const { data: workplaceManagers, isFetching: isLoadingWorkplaceManagers, isError: hasFailedToLoadWorkplaceManagers } = useGetCompanyManagersQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedLocalContacts
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (workplaceManagers?.length > 0) {
            const idsFromParams = getStringArrayParamValue('localContacts')
            dispatch(setSelectedLocalContacts(workplaceManagers.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workplaceManagers])

    return (
        <Autocomplete
            options={workplaceManagers ?? []}
            value={selectedLocalContacts}
            onChange={(_, value) => dispatch(setSelectedLocalContacts(value))}
            getOptionLabel={(nm) => nm.fullName}
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Lokal kontakt'
                error={hasFailedToLoadWorkplaceManagers}
                helperText={hasFailedToLoadWorkplaceManagers ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingWorkplaceManagers || hasFailedToLoadWorkplaceManagers || isSearching}
        />
    )
}