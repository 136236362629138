import React from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Stack, Typography } from '@mui/material'
import { DataGrid, GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridRowParams, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid'
import { MAX_NUMBER_OF_SAP_SEARCH_RESULTS, MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS } from '@local/src/Utils/network/endpoints/WorkplacesSearch/workplacesSearchApi'
import { IWorkplaceSearchResult } from '@local/src/Models/WorkplaceSearch/WorkplaceSearchResult.model'
import { DEFAULT_PAGE_SIZES, IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'
import { companyOverviewUrl } from '@local/src/basename'

import { CreateCompanyButton } from '../CreateCompanyModal/CreateCompanyModal'

import { workplaceSearchResultColumns } from './workplaceSearchResultColumns'


export interface IWorkplaceSearchResultProps {
    result?: IWorkplaceSearchResult
    isSearching: boolean
    didSearchFail: boolean,
    paging: IPagingModel,
    onPaging: (value: IPagingModel) => void
    sorting: ISortingModel,
    onSorting: (value: ISortingModel) => void
}

export const WorkplaceSearchResult = ({ result, isSearching, didSearchFail, paging, onPaging, sorting, onSorting }: IWorkplaceSearchResultProps) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('workplace-search-visible-columns', {} as GridColumnVisibilityModel)
    const [density, setDensity] = useLocalStorage('workplace-search-density', 'standard' as GridDensity)

    const useAzureSearch = useIsFeatureEnabled('OR-Frontend_utilizeCompanyNewSearch_temp_240617')
    const history = useHistory()


    if (didSearchFail)
        return <Alert severity='error'>Kunde inte hämta företag - vänligen försök igen</Alert>

    const maxResultsReached = result?.totalCount >= (useAzureSearch ? MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS : MAX_NUMBER_OF_SAP_SEARCH_RESULTS)




    const mapFromSortingModel = (): GridSortModel => ([{
        field: sorting.property,
        sort: sorting.order === 'ascending' ? 'asc' : 'desc'
    }])
    const handleOnSortingChanged = (sorting: GridSortModel) => {
        onSorting({
            property: sorting.at(0).field,
            order: sorting.at(0).sort === 'asc' ? 'ascending' : 'descending'
        })
    }

    return (
        <Stack direction='column' spacing={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h4'>Sökresultat {result?.totalCount >= 0 ? `(${result.totalCount.toLocaleString('sv-SE')})` : ''}</Typography>
                <CreateCompanyButton />
            </Stack>
            {maxResultsReached && <Alert severity='warning'>Maximalt antal sökresultat uppnått. Förfina sökningen om du vill vara säker på att få med alla relevanta resultat.</Alert>}
            <DataGrid
                loading={isSearching}
                columns={workplaceSearchResultColumns()}
                rows={result?.workplaces ?? []}
                rowCount={result?.totalCount ?? 0}
                getRowId={w => w.workplaceId}
                onRowClick={(params: GridRowParams<IWorkplace>) => params.row?.workplaceId && history.push(companyOverviewUrl(params.row.workplaceId))}
                autoHeight
                disableVirtualization
                disableColumnMenu
                disableRowSelectionOnClick
                paginationModel={mapFromPagingModel(paging)}
                paginationMode='server'
                onPaginationModelChange={(p: GridPaginationModel) => onPaging({
                    skip: p.page * p.pageSize,
                    take: p.pageSize
                })}
                pageSizeOptions={DEFAULT_PAGE_SIZES}
                sortModel={mapFromSortingModel()}
                sortingMode='server'
                onSortModelChange={handleOnSortingChanged}
                sortingOrder={['asc', 'desc']}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={setVisibleColumns}
                density={density}
                onStateChange={(state) => {
                    if (state.density.value !== density)
                        setDensity(state.density.value as GridDensity)
                }}
                slots={{
                    noResultsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    noRowsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    toolbar: () =>
                        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <GridToolbarColumnsButton />
                            <GridToolbarDensitySelector />
                        </GridToolbarContainer>,
                }}
                sx={{
                    "& div[role=cell]:focus": {
                        outline: "none !important"
                    },
                    "& div[role=cell]:focus-within": {
                        outline: "none !important"
                    }
                }}
                componentsProps={{ panel: { placement: 'bottom-end' } }} // Workaround since the new slot props are broken and reset everything when changing just panel.placement
            />
        </Stack>
    )
}

export const mapFromPagingModel = (paging: IPagingModel): GridPaginationModel => ({
    page: Math.floor(paging.skip / paging.take),
    pageSize: paging.take
})