import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import { useGetTrrIndustriesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { loadedFilterFromUrl, setSelectedTrrIndustries } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const TrrIndustryFilter = ({ isSearching }: IFilterProps) => {
    const { data: trrIndustries, isFetching: isLoadingTrrIndustries, isError: hasFailedToLoadTrrIndustries } = useGetTrrIndustriesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedTrrIndustries
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (trrIndustries?.length > 0) {
            const idsFromParams = getStringArrayParamValue('trrIndustries')
            dispatch(setSelectedTrrIndustries(trrIndustries.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trrIndustries])

    return (
        <Autocomplete
            options={trrIndustries ?? []}
            value={selectedTrrIndustries}
            onChange={(_, value) => dispatch(setSelectedTrrIndustries(value))}
            getOptionLabel={(ti) => ti.description}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Huvudbransch'
                error={hasFailedToLoadTrrIndustries}
                helperText={hasFailedToLoadTrrIndustries ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingTrrIndustries || hasFailedToLoadTrrIndustries || isSearching}
        />
    )
}