import { RootState, useAppDispatch } from '@local/src/Store/configureStore'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetMunicipalitiesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'

import { loadedFilterFromUrl, setSelectedMunicipalities } from '../WorkplaceSearch.slice'

import { IFilterProps } from './IFilterProps'

export const MunicipalityFilter = ({ isSearching }: IFilterProps) => {
    const { data: municipalities, isFetching: isLoadingMunicipalities, isError: hasFailedToLoadMunicipalities } = useGetMunicipalitiesQuery()
    const { getStringArrayParamValue } = useQueryParams()

    const {
        selectedCounties,
        selectedMunicipalities
    } = useSelector((state: RootState) => state.workplaceSearch)
    const dispatch = useAppDispatch()

    const availableMunicipalities = selectedCounties.length > 0 ? municipalities?.filter(m => selectedCounties.map(c => c.id).includes(m.countyId)) : municipalities

    useEffect(() => {
        if (municipalities?.length > 0) {
            const idsFromParams = getStringArrayParamValue('municipalities')
            dispatch(setSelectedMunicipalities(municipalities.filter(x => idsFromParams.includes(x.id))))

            if (idsFromParams.length > 0)
                dispatch(loadedFilterFromUrl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [municipalities])

    return (
        <Autocomplete
            options={availableMunicipalities ?? []}
            value={selectedMunicipalities}
            onChange={(_, value) => dispatch(setSelectedMunicipalities(value))}
            getOptionLabel={(m) => m.name}
            multiple
            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label='Kommun'
                error={hasFailedToLoadMunicipalities}
                helperText={hasFailedToLoadMunicipalities ? 'Kunde inte hämta filteralternativ' : undefined} />}
            disabled={isLoadingMunicipalities || hasFailedToLoadMunicipalities || isSearching}
        />
    )
}