import React from 'react'
import { useGetMyWorkplacesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { DataGrid, GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridRowParams, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { Alert, Stack, Typography } from '@mui/material'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'
import { companyOverviewUrl } from '@local/src/basename'
import { useHistory } from 'react-router-dom'

import { COLUMN_NAMES, workplaceSearchResultColumns } from '../WorkplaceSearch/workplaceSearchResultColumns'
import { CreateCompanyButton } from '../CreateCompanyModal/CreateCompanyModal'
import { mapFromPagingModel } from '../WorkplaceSearch/WorkplaceSearchResult'

export const MyWorkplaces = () => {
    const { data: myWorkplaces, isFetching: isLoading, isError: failedToLoadMyWorkplaces } = useGetMyWorkplacesQuery()
    const history = useHistory()

    const [visibleColumns, setVisibleColumns] = useLocalStorage('workplace-search-visible-columns', {} as GridColumnVisibilityModel)
    const [density, setDensity] = useLocalStorage('workplace-search-density', 'standard' as GridDensity)

    const [sorting, setSorting] = useLocalStorage('my-workplaces-sorting', { property: COLUMN_NAMES[1], order: 'ascending' } as ISortingModel)
    const [paging, setPaging] = useLocalStorage('my-workplaces-paging', { skip: 0, take: DEFAULT_PAGE_SIZE } as IPagingModel)

    const mapFromSortingModel = (): GridSortModel => ([{
        field: sorting.property,
        sort: sorting.order === 'ascending' ? 'asc' : 'desc'
    }])
    const handleOnSortingChanged = (sorting: GridSortModel) => {
        setSorting({
            property: sorting.at(0).field,
            order: sorting.at(0).sort === 'asc' ? 'ascending' : 'descending'
        })
    }

    if (failedToLoadMyWorkplaces)
        return <Alert severity='error'>Kunde inte hämta företag - vänligen försök igen</Alert>

    return (
        <Stack direction='column' spacing={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h4'>Mina företag {myWorkplaces?.length >= 0 ? `(${myWorkplaces?.length.toLocaleString('sv-SE')})` : ''}</Typography>
                <CreateCompanyButton />
            </Stack>
            <DataGrid
                loading={isLoading}
                autoHeight
                columns={workplaceSearchResultColumns()}
                rows={myWorkplaces ?? []}
                getRowId={w => w.workplaceId}
                disableRowSelectionOnClick
                sortModel={mapFromSortingModel()}
                onSortModelChange={handleOnSortingChanged}
                sortingOrder={['asc', 'desc']}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={setVisibleColumns}
                density={density}
                disableColumnMenu
                paginationModel={mapFromPagingModel(paging)}
                onPaginationModelChange={(p: GridPaginationModel) => setPaging({
                    skip: p.page * p.pageSize,
                    take: p.pageSize
                })}
                onRowClick={(params: GridRowParams<IWorkplace>) => params.row?.workplaceId && history.push(companyOverviewUrl(params.row.workplaceId))}
                pageSizeOptions={DEFAULT_PAGE_SIZES}
                onStateChange={(state) => {
                    if (state.density.value !== density)
                        setDensity(state.density.value as GridDensity)
                }}
                slots={{
                    noResultsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    noRowsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    toolbar: () =>
                        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <GridToolbarColumnsButton />
                            <GridToolbarDensitySelector />
                        </GridToolbarContainer>,
                }}
                sx={{
                    "& div[role=cell]:focus": {
                        outline: "none !important"
                    },
                    "& div[role=cell]:focus-within": {
                        outline: "none !important"
                    }
                }}
                componentsProps={{ panel: { placement: 'bottom-end' } }}
            />
        </Stack>
    )
}