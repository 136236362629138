import { ISelectOption } from '@local/src/App.types'

import { organisationsregistretApi } from '../organisationsregistretApi'

export const unionsApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnions: builder.query<ISelectOption[], void>({
      query: () => ({ url: 'artifacts/unions', method: 'GET' }),
      transformResponse: (data: { accountID: string; name2: string }[]) =>
        data.map((d) => ({ value: d.accountID, label: d.name2 }) as ISelectOption),
    }),
  }),
})

export const { useGetUnionsQuery } = unionsApi
