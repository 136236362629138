import * as React from 'react'
import { formatOrgNr } from '@local/Utils/helpers'
import { GridColDef } from '@mui/x-data-grid'
import { Box, Tooltip } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'

import { ContactsSearch } from '../../Contacts.types'


export const contactColumns: GridColDef<ContactsSearch>[] = [
  {
    headerName: 'Huvudkontakt',
    field: 'isMainContact',
    renderCell: ({ row }) => {
      return row.isMainContact ? (
        <Box width='100%' display='flex' justifyContent='center' data-testid='isMainContact'>
          <Tooltip title='Huvudkontakt'>
            <StarIcon color='primary' />
          </Tooltip>
        </Box>
      ) : ''
    },
    flex: 1,
    maxWidth: 125,
    hideable: false,
    filterable: false,
  },
  {
    headerName: 'Namn',
    field: 'fullName',
    flex: 1,
  },
  {
    headerName: 'Mobil',
    field: 'mobileNumber',
    flex: 1,
  },
  {
    headerName: 'E-post',
    field: 'email',
    flex: 1,
  },
  {
    headerName: 'Roll',
    field: 'roleLevel2',
    flex: 1,
  },
  {
    headerName: 'Org.nummer',
    field: 'organizationalNumber',
    renderCell: ({ row }) => formatOrgNr(row?.organizationalNumber),
    flex: 1,
  },
  {
    headerName: 'Arbetsställe',
    field: 'workplaceName',
    flex: 1,
  },
  {
    headerName: 'Kommun',
    field: 'municipalityName',
    flex: 1,
  },
  {
    headerName: 'D&B (Antal tjm)',
    field: 'whiteCollarBisnode.whitecollarBisnodeDescription',
    valueGetter: ({ row }) => row?.whiteCollarBisnode?.whitecollarBisnodeDescription,
    flex: 1,
  },
  {
    headerName: 'TRR (Antal tjm)',
    field: 'whiteCollarNumberTRR',
    flex: 1,
  },
]
