import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useParams, useHistory } from 'react-router-dom'
import { companyContactUrl, contactDetailsUrl } from '@local/src/basename'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { CountryCode, IContactStatus, IRoleLevelTwo, ISelectOption } from '@local/src/App.types'
import ConfirmationModal from '@local/src/Components/ConfirmationModal/ConfirmationModal'
import { formControlHasError } from '@local/src/Utils/helpers'
import Loading from '@local/src/Components/Loading/Loading'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { RootState, useAppDispatch } from '@local/Store/configureStore'
import {
  createContact,
  deleteContact,
  fetchContactsData,
  fetchDetailedContactData,
  updateContact,
} from '@local/Scenes/Company/Company.actions'
import { useSelector } from 'react-redux'
import { resetContactsTab } from '@local/Scenes/Company/Company.reducers'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { useGetUnionsQuery } from '@local/src/Utils/network/endpoints/unionsApi'
import { useGetCountryCodesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { useGetCompanyContactStatusesQuery, useGetLevelOneRolesQuery, useGetLevelTwoRolesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { ContactModel, CreateContact, HandleContactFormValues } from '../Contacts.model'
import { companyContactsSelector, createOrUpdateContactFailedSelector, detailedContactSelector, handleContactErrorMessageSelector } from '../../../Company.selectors'

import DuplicateContacts from './DuplicateContacts/DuplicateContacts'
import { validationSchema } from './HandleContact.validation'

const initialValues = {
  cellphoneCountry: 'SE',
  telephoneCountry: 'SE',
  contactStatus: '0',
  firstName: '',
  lastName: '',
  email: '',
  linkedin: '',
  ommagasine: 'false',
  cellphone: '',
  telephone: '',
  roleLevelOne: '',
  roleLevelTwo: '',
  union: '',
  comment: '',
  roleGroup: '',
  isMainContact: false,
}

const HandleContact = () => {

  const formikRef = useRef<FormikProps<HandleContactFormValues>>()
  const history = useHistory()
  const { companyGuid, contactGuid }: { companyGuid: string; contactGuid: string } = useParams()
  const dispatch = useAppDispatch()
  const isMyContactsPage = companyGuid === undefined
  const create = history.location.pathname.endsWith('skapa')
  const updateInProgress = useSelector((state: RootState) => state.company.updateContactInProgress)
  const createInProgress = useSelector((state: RootState) => state.company.createContactInProgress)
  const deleteInProgress = useSelector((state: RootState) => state.company.deleteContactInProgress)
  const isSaveInProgress = useSelector((state: RootState) => state.company.createOrUpdateContactStarted || state.company.deleteContactInProgress)
  const [initialFormValues, setInitialFormValues] = useState<HandleContactFormValues>(null)
  const [duplicateNameContacts, setDuplicateNameContacts] = useState<ContactModel[]>([])
  const [duplicateEmailContacts, setDuplicateEmailContacts] = useState<ContactModel[]>([])
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false)

  const contact = useStateSelector(detailedContactSelector)
  const companyContacts = useStateSelector(companyContactsSelector)
  const errorMessage = useStateSelector(handleContactErrorMessageSelector)
  const createOrUpdateFailed = useStateSelector(createOrUpdateContactFailedSelector)

  const { data: roleLevelOne, isLoading: isLoadingRoleLevelOne } = useGetLevelOneRolesQuery()
  const { data: roleLevelTwo, isLoading: isLoadingRoleLevelTwo } = useGetLevelTwoRolesQuery()
  const { data: unions, isLoading: isLoadingUnions } = useGetUnionsQuery()
  const { data: countryCodes, isLoading: isLoadingCountryCodes } = useGetCountryCodesQuery()
  const { data: contactStatuses, isLoading: isLoadingContactStatuses } = useGetCompanyContactStatusesQuery()


  const loading = !!initialFormValues === false ||
    isLoadingRoleLevelOne ||
    isLoadingRoleLevelTwo ||
    isLoadingUnions ||
    isLoadingCountryCodes ||
    isLoadingContactStatuses

  useEffect(() => {
    if (!create) {
      dispatch(fetchDetailedContactData(companyGuid, contactGuid))
    }

    return () => {
      dispatch(resetContactsTab())
    }
  }, [companyGuid, contactGuid, create, dispatch])

  useEffect(() => {
    dispatch(fetchContactsData(companyGuid))
  }, [companyGuid, dispatch])

  const previousUpdateInProgress = useRef(updateInProgress)

  useEffect(() => {
    const finishedUpdating = previousUpdateInProgress.current && !updateInProgress && !createOrUpdateFailed
    if (finishedUpdating) {
      history.push(contactDetailsUrl(companyGuid, contactGuid))
    }

    previousUpdateInProgress.current = updateInProgress
  }, [companyGuid, contactGuid, createOrUpdateFailed, history, updateInProgress])

  const previousCreateInProgress = useRef(createInProgress)

  useEffect(() => {
    const finishedCreating = previousCreateInProgress.current && !createInProgress && !createOrUpdateFailed
    if (finishedCreating) {
      history.push(companyContactUrl(companyGuid))
    }

    previousCreateInProgress.current = createInProgress
  }, [companyGuid, contactGuid, history, createInProgress, createOrUpdateFailed])

  const previousDeleteInProgress = useRef(deleteInProgress)

  useEffect(() => {
    const finishedDeleting = previousDeleteInProgress.current && !deleteInProgress && !createOrUpdateFailed
    if (finishedDeleting) {
      history.push(companyContactUrl(companyGuid))
    }

    previousDeleteInProgress.current = deleteInProgress
  }, [companyGuid, contactGuid, history, deleteInProgress, createOrUpdateFailed])

  const goBack = () => history.push(companyContactUrl(companyGuid))

  const getValidationError = (formikProps: FormikProps<HandleContactFormValues>, formProp: string) =>
    (formikProps.errors && formikProps.touched[formProp] && formikProps.errors[formProp]) ||
    (!create && formikProps.errors && formikProps.errors[formProp])

  const findValorEmpty = (formikProps: FormikProps<HandleContactFormValues>) => {
    const v = roleLevelTwo.find((value: ISelectOption) => {
      return value.value === formikProps.values.roleLevelTwo
    })
    return v?.value ? v.value : ''
  }

  const onSubmit = (values: HandleContactFormValues) => {
    if (create) {
      const contactData: CreateContact = {
        workplaceBPGuid: companyGuid,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
        displayBokaLink: true,
        linkedIn: values.linkedin,
        mobileCountry: values.cellphoneCountry,
        mobileNumber: values.cellphone,
        omMagasinKey: 'false',
        roleLevel1: values.roleLevelOne,
        roleLevel2: values.roleLevelTwo,
        telephoneCountry: values.telephoneCountry,
        telephoneNumber: values.telephone,
        unionID: values.union,
        status: values.contactStatus,
        comment: values.comment,
        isMainContact: values.isMainContact,
      }

      dispatch(createContact(contactData))
    } else {
      const contactData: ContactModel = JSON.parse(JSON.stringify(contact))
      contactData.firstName = values.firstName.trim()
      contactData.lastName = values.lastName.trim()
      contactData.email = values.email
      contactData.linkedIn = values.linkedin
      contactData.omMagazineKey = values.ommagasine
      contactData.mobile.number = values.cellphone
      contactData.mobile.countryCode = values.cellphoneCountry
      contactData.phone.number = values.telephone
      contactData.phone.countryCode = values.telephoneCountry
      contactData.roleLevelOne.roleLevel1Id = values.roleLevelOne
      contactData.roleLevelTwo.roleLevel2Id = values.roleLevelTwo
      contactData.union.unionId = values.union
      contactData.status.statusId = values.contactStatus
      contactData.comment = values.comment
      contactData.isMainContact = values.isMainContact

      dispatch(updateContact(contactData, isMyContactsPage))
    }
  }

  useEffect(() => {
    if (create) {
      setInitialFormValues(initialValues)
    } else if (contact) {
      setInitialFormValues({
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        linkedin: contact.linkedIn,
        ommagasine: contact.omMagazineKey === 'true' ? 'true' : 'false',
        cellphone: contact.mobile.number,
        cellphoneCountry: contact.mobile.countryCode || 'SE',
        telephone: contact.phone.number,
        telephoneCountry: contact.phone.countryCode || 'SE',
        roleLevelOne: contact.roleLevelOne.roleLevel1Id,
        roleLevelTwo: contact.roleLevelTwo.roleLevel2Id,
        union: contact.union.unionId,
        contactStatus: contact.status.statusId,
        comment: contact.comment,
        roleGroup: '',
        isMainContact: contact.isMainContact,
      })
    }
  }, [contact, create])

  const handleSubmitForm = (formikProps: FormikProps<HandleContactFormValues>) => {
    void formikProps.submitForm()
  }

  const getNameDuplicates = (
    companyContacts: ContactModel[],
    contactFirstName: string,
    contactLastName: string,
    contactGuid: string
  ) =>
    companyContacts.filter(
      contact =>
        contact.contactId !== contactGuid &&
        contact.firstName?.toLowerCase().trim() === contactFirstName?.toLowerCase().trim() &&
        contact.lastName?.toLowerCase().trim() === contactLastName?.toLowerCase().trim()
    )

  const getEmailDuplicates = (companyContacts: ContactModel[], contactEmail: string, contactGuid: string) =>
    companyContacts.filter(
      contact =>
        contactEmail?.trim().length > 0 &&
        contact.contactId !== contactGuid &&
        contact.email?.toLowerCase().trim() === contactEmail?.toLowerCase().trim()
    )

  const checkDuplicateContacts = useCallback(
    ({ firstName, lastName, email, contactId }: ContactModel) => {
      const nameDuplicates = getNameDuplicates(companyContacts, firstName, lastName, contactId)
      const emailDuplicates = getEmailDuplicates(companyContacts, email, contactId)

      setDuplicateNameContacts(nameDuplicates)
      setDuplicateEmailContacts(emailDuplicates)
    },
    [companyContacts]
  )

  const checkDuplicateContactsOnBlur = useCallback(
    (contactGuid: string) => {
      const firstName = formikRef.current?.values?.firstName
      const lastName = formikRef.current?.values?.lastName
      const email = formikRef.current?.values?.email

      const nameDuplicates = getNameDuplicates(companyContacts, firstName, lastName, contactGuid)
      const emailDuplicates = getEmailDuplicates(companyContacts, email, contactGuid)

      setDuplicateNameContacts(nameDuplicates)
      setDuplicateEmailContacts(emailDuplicates)
    },
    [companyContacts, formikRef]
  )

  useEffect(() => {
    if (!!contact && contact.contactId === contactGuid) {
      checkDuplicateContacts(contact)
    }
  }, [contact, checkDuplicateContacts, contactGuid])

  const handleDeleteContact = () => {
    setIsDeleteInProgress(true)
    setIsShowingModal(false)
    dispatch(deleteContact(companyGuid, contactGuid, false))
  }

  const showUnionDropdown = (formikProps: FormikProps<HandleContactFormValues>) => {
    return formikProps.values.roleLevelTwo === '0011' || formikProps.values.roleLevelTwo === '0012'
  }

  if (loading)
    return <Loading />

  return (
    <Stack display='flex' justifyContent='flex-start' flexDirection='column' maxWidth={752}>
      {createOrUpdateFailed && (
        <Alert severity="warning">Fel vid skapande/uppdatering av kontaktperson: {errorMessage}</Alert>
      )}
      <Typography variant='h5'>{create ? 'Skapa kontaktperson' : 'Redigera kontaktperson'}</Typography>

      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {(formikProps: FormikProps<HandleContactFormValues>) => (
          <Stack component='form' onSubmit={formikProps.handleSubmit}>
            <Stack maxWidth={480}>
              <Typography variant='h6' my={2}>Namn</Typography>
              <Box display='flex' gap={2} mb={1}>
                <TextField
                  fullWidth
                  helperText={getValidationError(formikProps, 'firstName')}
                  error={formControlHasError(formikProps, 'firstName')}
                  label="Förnamn"
                  name="firstName"
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    formikProps.handleBlur(event)
                    checkDuplicateContactsOnBlur(contactGuid)
                  }}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.firstName ?? ''}
                />
                <TextField
                  fullWidth
                  helperText={getValidationError(formikProps, 'lastName')}
                  error={formControlHasError(formikProps, 'lastName')}
                  label="Efternamn"
                  name="lastName"
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    formikProps.handleBlur(event)
                    checkDuplicateContactsOnBlur(contactGuid)
                  }}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.lastName ?? ''}
                />
              </Box>
            </Stack>
            <Stack>
              <DuplicateContacts
                contacts={duplicateNameContacts}
                companyId={companyGuid}
                warningText='Det finns redan kontakter med detta namn på arbetsstället.'
              />
              <Box>
                <FormControlLabel
                  label="Huvudkontakt"
                  control={
                    <Checkbox
                      checked={formikProps.values.isMainContact}
                      name="isMainContact"
                      onChange={formikProps.handleChange}
                    />
                  }
                />
                <InformationPopover
                  infoText="Syftet med att märka upp en kontakt som en huvudkontakt är att du snabbt kan få fram de
                  personer som du jobbar med mest frekvent, exempelvis vid gruppmejl."
                  iconSize="small"
                />
              </Box>
            </Stack>
            <Stack display='flex' flexDirection='column' gap={2} maxWidth={480} my={2}>
              <Typography variant='h6'>Kontaktuppgifter</Typography>
              <TextField
                helperText={getValidationError(formikProps, 'email')}
                error={formControlHasError(formikProps, 'email')}
                label="E-post"
                name="email"
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  formikProps.handleBlur(event)
                  checkDuplicateContactsOnBlur(contactGuid)
                }}
                onChange={formikProps.handleChange}
                value={formikProps.values.email ?? ''}
              />
            </Stack>
            <DuplicateContacts
              contacts={duplicateEmailContacts}
              companyId={companyGuid}
              warningText={'Det finns redan kontakter med denna e-postadress på arbetsstället.'}
            />
            <Stack display='flex' flexDirection='column' gap={2} maxWidth={480}>
              <Box display='flex' gap={1}>
                <Dropdown
                  label='Landskod'
                  defaultValue='SE'
                  value={countryCodes.find(val => val.value === formikProps.values.cellphoneCountry)?.value ?? ''}
                  name='cellphoneCountry'
                  error={formControlHasError(formikProps, 'cellphoneCountry')}
                  errorText={getValidationError(formikProps, 'cellphoneCountry')}
                  options={countryCodes}
                  getOptionLabel={(value: CountryCode) => `${value.label} (+${value.phoneCode})`}
                  getOptionValue={(value: CountryCode) => value.value}
                  onChange={(event: SelectChangeEvent<string>) => void formikProps.setFieldValue('cellphoneCountry', event.target.value)}
                  sx={{ maxWidth: 152 }}
                />
                <TextField
                  fullWidth
                  helperText={getValidationError(formikProps, 'cellphone')}
                  error={formControlHasError(formikProps, 'cellphone')}
                  label="Mobil"
                  name="cellphone"
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.cellphone ?? ''}
                />
              </Box>
              <Box display='flex' gap={1}>
                <Dropdown
                  label='Landskod'
                  name='telephoneCountry'
                  defaultValue='SE'
                  onChange={(event: SelectChangeEvent<string>) => void formikProps.setFieldValue('telephoneCountry', event.target.value)}
                  error={formControlHasError(formikProps, 'telephoneCountry')}
                  errorText={getValidationError(formikProps, 'telephoneCountry')}
                  getOptionLabel={(value: CountryCode) => `${value.label} (+${value.phoneCode})`}
                  getOptionValue={(value: CountryCode) => value.value}
                  options={countryCodes}
                  value={countryCodes.find((val: ISelectOption) => val.value === formikProps.values.telephoneCountry)?.value ?? ''}
                  sx={{ maxWidth: 152 }}
                />
                <TextField
                  fullWidth
                  helperText={getValidationError(formikProps, 'telephone')}
                  error={formControlHasError(formikProps, 'telephone')}
                  label="Telefon"
                  name="telephone"
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.telephone ?? ''}
                />
              </Box>
              <TextField
                helperText={getValidationError(formikProps, 'linkedin')}
                error={formControlHasError(formikProps, 'linkedin')}
                label="Linkedin"
                name="linkedin"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.linkedin ?? ''}
              />
            </Stack>
            <Stack display='flex' flexDirection='column' gap={2} maxWidth={480} mt={3}>
              <Typography variant='h6'>Roll och funktion</Typography>
              <Dropdown
                label='Kategori'
                name='roleLevelOne'
                onChange={(event: SelectChangeEvent<string>) => {
                  void formikProps.setFieldValue('roleLevelOne', event.target.value)
                  void formikProps.setFieldValue('roleLevelTwo', '')
                }}
                value={formikProps.values.roleLevelOne ?? ''}
                errorText={getValidationError(formikProps, 'roleLevelOne')}
                error={formControlHasError(formikProps, 'roleLevelOne')}
                options={roleLevelOne}
                getOptionLabel={(value: ISelectOption) => value.label}
                getOptionValue={(value: ISelectOption) => value.value}
              />
              <Dropdown
                label='Roll'
                name='roleLevelTwo'
                onChange={(event: SelectChangeEvent<string>) => {
                  void formikProps.setFieldValue('roleLevelTwo', event.target.value)
                }}
                value={findValorEmpty(formikProps)}
                errorText={getValidationError(formikProps, 'roleLevelTwo')}
                error={formControlHasError(formikProps, 'roleLevelTwo')}
                options={roleLevelTwo.filter((value: IRoleLevelTwo) => value.level1 === formikProps.values.roleLevelOne)}
                getOptionLabel={(value: IRoleLevelTwo) => value.label}
                getOptionValue={(value: IRoleLevelTwo) => value.value}
              />
              {showUnionDropdown(formikProps) && (
                <Dropdown
                  label='Fackförbund'
                  name='union'
                  onChange={(event: SelectChangeEvent<string>) => {
                    void formikProps.setFieldValue('union', event.target.value)
                  }}
                  value={unions.find(value => value.value === formikProps.values.union)?.value ?? ''}
                  error={formControlHasError(formikProps, 'union')}
                  errorText={formControlHasError(formikProps, 'union') && formikProps.errors?.union}
                  options={unions}
                  getOptionLabel={(value: ISelectOption) => value.label}
                  getOptionValue={(value: ISelectOption) => value.value}
                />
              )}
              <Dropdown
                label='Status'
                name='contactStatus'
                onChange={(event: SelectChangeEvent<string>) => {
                  void formikProps.setFieldValue('contactStatus', event.target.value)
                }}
                value={formikProps.values.contactStatus}
                error={formControlHasError(formikProps, 'contactStatus')}
                errorText={getValidationError(formikProps, 'contactStatus')}
                options={contactStatuses.filter(val => val.value !== '')}
                getOptionLabel={(value: IContactStatus) => value.label}
                getOptionValue={(value: IContactStatus) => value.value}
              />
            </Stack>
            <Stack display='flex' flexDirection='column' gap={2} maxWidth={480} mt={3}>
              <Typography variant='h6'>Notering</Typography>
              <TextField
                multiline
                name='comment'
                value={formikProps.values.comment ?? ''}
                onChange={formikProps.handleChange}
                minRows={4}
              />
            </Stack>
            <Stack direction='row' mt={3} gap={2}>
              <Button
                size="large"
                onClick={() => handleSubmitForm(formikProps)}
                disabled={isSaveInProgress}
              >
                {isSaveInProgress && !isDeleteInProgress ? <CircularProgress size="1rem" /> : 'Spara'}
              </Button>
              {!create && (
                <Button
                  size="large"
                  onClick={() => setIsShowingModal(true)}
                  variant="outlined"
                  disabled={isSaveInProgress}
                >
                  {isSaveInProgress && isDeleteInProgress ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    'Ta bort'
                  )}
                </Button>
              )}
              <Button size="large" onClick={goBack} variant="outlined" disabled={isSaveInProgress}>
                Avbryt
              </Button>
            </Stack>
          </Stack>
        )}
      </Formik>
      <ConfirmationModal
        active={isShowingModal}
        close={() => setIsShowingModal(false)}
        submit={handleDeleteContact}
        message={`
          Skapade händelser finns kvar när en kontaktperson tas bort. Däremot försvinner personens namn ifrån händelsen.

          Är du säker på att du vill ta bort denna kontaktperson?`}
        title='Ta bort kontaktperson'
      />
    </Stack>
  )
}

export default HandleContact
