import axios, { AxiosError } from 'axios'
import { parseErrorMessage } from '@local/Utils/helpers'
import { getOIDCUser } from '@trr/app-shell-communication'

import getConfig from '../ConfigService'

const { API_URL } = getConfig()
const axiosInstance = axios.create({
  baseURL: API_URL
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(parseErrorMessage(error as AxiosError))
  }
)

axiosInstance.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${getOIDCUser().id_token}`
  return config
})

export { axiosInstance }
