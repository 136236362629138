import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parse } from '@local/Utils/helpers/queryString'
import { orgBasepath } from '@local/src/basename'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'
import SearchField from '@local/src/Components/SearchField/SearchField'
import { Box } from '@mui/material'
import { useAppDispatch } from '@local/src/Store/configureStore'

import { searchMyContacts } from './../Contacts.actions'
import ContactAdvancedSearchForm from './../ContactAdvancedSearch/ContactAdvancedSearchForm'

const ContactsForm = (): JSX.Element => {
  const [value, setValue] = useState('')
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    const query = parse(location.search)
    const search = (query.search as string) || ''
    setValue(search)

    dispatch(searchMyContacts(search))
  }, [dispatch])

  const query = parse(location.search)
  const isAdvancedSearch = query.advanced > ''

  const submitForm = (value: string) => {
    history.push(`${orgBasepath}/kontakt?search=${encodeURIComponent(value)}`)
    dispatch(searchMyContacts(value))
  }

  const handleToggleSearchForm = (opened: boolean) => {
    const params = new URLSearchParams(opened ? { advanced: 'true' } : {})
    history.push({ pathname: location.pathname, search: params.toString() })
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} marginTop={3}>
      <SearchField
        label="Sök bland mina kontaktpersoner"
        value={value}
        search={submitForm}
        disabled={isAdvancedSearch}
      />

      <AccordionBox
        label={isAdvancedSearch ? 'Sök bland mina kontaktpersoner' : 'Sök bland alla kontaktpersoner i ORG'}
        expanded={isAdvancedSearch}
        onToggle={handleToggleSearchForm}
      >
        {isAdvancedSearch && <ContactAdvancedSearchForm />}
      </AccordionBox>
    </Box>
  )
}

export default ContactsForm
