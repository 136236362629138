import React, { useEffect, useMemo } from 'react'
import { Stack } from '@mui/material'
import { useLazySearchWorkplacesQuery } from '@local/src/Utils/network/endpoints/WorkplacesSearch/workplacesSearchApi'
import { RootState } from '@local/src/Store/configureStore'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { DEFAULT_PAGE_SIZE, IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { IWorkplaceSearchFilterModel } from '@local/src/Models/WorkplaceSearch/IWorkplaceSearchFilter.model'
import { useQueryParams } from '@local/src/Utils/helpers/useQueryParams'
import useDebounce from '@local/src/Utils/helpers/useDebounce'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import { MyWorkplaces } from '../MyWorkplaces/MyWorkplaces'

import { WorkplaceSearchResult } from './WorkplaceSearchResult'
import { WorkplaceSearchFilter } from './WorkplaceSearchFilter'
import { COLUMN_NAMES } from './workplaceSearchResultColumns'


export const WorkplaceSearch = () => {
    const [searchWorkplaces, { data: searchResults, isFetching: isSearching, isError: failedToSearch, isUninitialized: hasNotSearched }] = useLazySearchWorkplacesQuery()

    const [paging, setPaging] = useLocalStorage('workplace-search-paging', { skip: 0, take: DEFAULT_PAGE_SIZE } as IPagingModel)
    const [sorting, setSorting] = useLocalStorage('workplace-search-sorting', { property: COLUMN_NAMES[1], order: 'ascending' } as ISortingModel)

    const { setParamValue, setArrayParamValue } = useQueryParams()

    const useAzureSearch = useIsFeatureEnabled('OR-Frontend_utilizeCompanyNewSearch_temp_240617')

    const {
        nameOrgNumber,
        selectedCounties,
        selectedMunicipalities,
        selectedWorkplaceTypes,
        selectedTrrIndustries,
        selectedNationalManagers,
        selectedLocalManagers,
        selectedLocalContacts,
        selectedEligibilities,
        selectedWorkplaceStatuses,
        whiteCollarRange,
        payrollRange,
        isLocallyImportant,
        loadedFilterFromUrl
    } = useSelector((state: RootState) => state.workplaceSearch)

    const debouncedFilterLoadedFlag = useDebounce(loadedFilterFromUrl, 250)

    useEffect(() => {
        if (debouncedFilterLoadedFlag === true)
            search(mapFilters(), paging, sorting)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilterLoadedFlag])

    const mapFilters = () => ({
        nameOrgNumber: nameOrgNumber,
        counties: selectedCounties,
        municipalities: selectedMunicipalities,
        workplaceTypes: selectedWorkplaceTypes,
        trrIndustries: selectedTrrIndustries,
        nationalManagers: selectedNationalManagers,
        localManagers: selectedLocalManagers,
        localContacts: selectedLocalContacts,
        eligibilities: selectedEligibilities,
        workplaceStatuses: selectedWorkplaceStatuses,
        trrWhiteCollarCount: whiteCollarRange,
        payroll: payrollRange,
        isLocallyImportant: isLocallyImportant
    }) as IWorkplaceSearchFilterModel

    const handleOnPaging = (value: IPagingModel) => {
        setPaging(value)
        if (searchResults?.totalCount > 0)
            search(mapFilters(), value, sorting)
    }

    const handleOnSorting = (value: ISortingModel) => {
        const resetPaging = { skip: 0, take: paging.take } as IPagingModel
        setPaging(resetPaging)
        setSorting(value)
        if (searchResults?.totalCount > 0)
            search(mapFilters(), resetPaging, value)
    }

    const onSearch = () => {
        const resetPaging = { skip: 0, take: paging.take } as IPagingModel
        setPaging(resetPaging)
        search(mapFilters(), resetPaging, sorting)
    }

    const search = (filters: IWorkplaceSearchFilterModel, paging: IPagingModel, sorting: ISortingModel) => {
        writeFiltersToUrl(filters)
        void searchWorkplaces({
            filters,
            paging,
            sorting,
            preferredSource: useAzureSearch ? 'az' : 'sap'
        })
    }

    const writeFiltersToUrl = (filters: IWorkplaceSearchFilterModel) => {
        setParamValue('nameOrgNumber', filters.nameOrgNumber)
        setArrayParamValue('counties', filters.counties.map(m => m.id))
        setArrayParamValue('municipalities', filters.municipalities.map(m => m.id))
        setArrayParamValue('workplaceTypes', filters.workplaceTypes.map(m => m.id))
        setArrayParamValue('trrIndustries', filters.trrIndustries.map(m => m.id))
        setArrayParamValue('nationalManagers', filters.nationalManagers.map(m => m.id))
        setArrayParamValue('localManagers', filters.localManagers.map(m => m.id))
        setArrayParamValue('localContacts', filters.localContacts.map(m => m.id))
        setArrayParamValue('eligibilities', filters.eligibilities.map(m => m.id))
        setParamValue('whiteCollarFrom', filters.trrWhiteCollarCount.from)
        setParamValue('whiteCollarTo', filters.trrWhiteCollarCount.to)
        setParamValue('payrollFrom', filters.payroll.from)
        setParamValue('payrollTo', filters.payroll.to)
        setArrayParamValue('workplaceStatuses', filters.workplaceStatuses.map(m => m.id))
        setParamValue('isLocallyImportant', filters.isLocallyImportant)
    }

    const showMyWorkplaces = hasNotSearched

    return (
        <Stack>
            <WorkplaceSearchFilter isSearching={isSearching} onSearch={onSearch} />
            {useMemo(() => (showMyWorkplaces && <MyWorkplaces />), [showMyWorkplaces])}
            {useMemo(() => (!showMyWorkplaces && <WorkplaceSearchResult
                result={searchResults}
                isSearching={isSearching}
                didSearchFail={failedToSearch}
                paging={paging} onPaging={handleOnPaging}
                sorting={sorting} onSorting={handleOnSorting}
            // eslint-disable-next-line react-hooks/exhaustive-deps
            />), [failedToSearch, isSearching, paging, searchResults, showMyWorkplaces, sorting])}
        </Stack >
    )
}


