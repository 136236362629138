import { IWorkplaceSearchResult } from '@local/src/Models/WorkplaceSearch/WorkplaceSearchResult.model'
import { IWorkplaceSearchFilterModel } from '@local/src/Models/WorkplaceSearch/IWorkplaceSearchFilter.model'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { isOrgNumber } from '@local/src/Utils/helpers'

import { organisationsregistretApi } from '../../organisationsregistretApi'

import { IWorkplaceSearchResponseApiModel, mapToWorkplace } from './IWorkplacesSearchResponseApiModel'
import { IWorkplacesSearchApiModel } from './IWorkplacesSearchApiModel'

export const MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS = 100000
export const MAX_NUMBER_OF_SAP_SEARCH_RESULTS = 500

export const workplacesSearchApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    searchWorkplaces: builder.query<
      IWorkplaceSearchResult,
      {
        filters: IWorkplaceSearchFilterModel
        paging: IPagingModel
        sorting: ISortingModel
        preferredSource: string
      }
    >({
      query: ({ filters, paging, sorting, preferredSource }) => ({
        url: `workplaces/workplaceSearch2?preferredSource=${preferredSource}`,
        method: 'POST',
        body: mapToAllCompaniesSearch(filters, paging, sorting),
      }),
      transformResponse: (data: { results: IWorkplaceSearchResponseApiModel[]; totalCount: number }) => ({
        workplaces: data.results.map(mapToWorkplace),
        totalCount: Math.min(data.totalCount, MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS),
      }),
    }),
  }),
})

export const { useLazySearchWorkplacesQuery } = workplacesSearchApi

const mapToAllCompaniesSearch = (
  filters: IWorkplaceSearchFilterModel,
  paging: IPagingModel,
  sorting: ISortingModel
): IWorkplacesSearchApiModel => {
  return {
    fullName: isOrgNumber(filters.nameOrgNumber) ? undefined : filters.nameOrgNumber,
    organizationalNumber: isOrgNumber(filters.nameOrgNumber) ? filters.nameOrgNumber : undefined,
    countyCode: filters.counties?.map((c) => c.id),
    companyStatuses: filters.workplaceStatuses?.map((ws) => ws.id),
    companyTypes: filters.workplaceTypes?.map((wt) => wt.id),
    eligibilityStatuses: filters.eligibilities?.map((e) => e.id),
    localContact: filters.localContacts?.map((lc) => lc.id),
    localCustomerManager: filters.localManagers?.map((lm) => lm.id),
    locallyImportant: filters.isLocallyImportant,
    municipalityCode: filters.municipalities?.map((m) => m.id),
    nationalCustomerManager: filters.nationalManagers?.map((nm) => nm.id),
    payrollRange: filters.payroll,
    whiteCollarRangeTrr: filters.trrWhiteCollarCount,
    trrIndustries: filters.trrIndustries?.map((ti) => ti.id),
    skip: paging.skip,
    count: paging.take,
    orderBy: [
      {
        property: sorting.property,
        order: sorting.order,
      },
    ],
  }
}
